import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  createContext,
} from 'react';

import { API } from 'aws-amplify';

import { FieldGrid } from '../Fields';
import useErrorContext from '../../hooks/useErrorContext';
import useNavigation from '../../hooks/useNavigation';

import { ENTITY_CONFIG, hasField } from './constants';

export const ChannelContext = createContext();

export const useChannelContext = () => useContext(ChannelContext);

export const ChannelContextProvider = ({ children }) => {
  const [channels, setChannels] = useState([]);
  const { setError } = useErrorContext();
  const { pathUp } = useNavigation();

  const fetchChannels = useCallback(async () => {
    const { payload } = await API.get('admin', '/channels');
    setChannels(payload);
  }, [setChannels]);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const createChannel = useCallback(
    async (channel) => {
      try {
        const { entityType } = channel;
        const fns = new Set(ENTITY_CONFIG[entityType].supportedFunctions);

        let fn;
        if (fns.has('create')) {
          fn = 'create';
        } else if (fns.has('import')) {
          fn = 'import';
        } else {
          throw new Error('No create function');
        }

        const [intg, type] = entityType.toLowerCase().split(':');
        const { payload } = await API.post(
          'admin',
          `/channel/${intg}/${type}/${fn}`,
          { body: channel }
        );
        setChannels([...channels, payload]);
        pathUp();
      } catch (error) {
        console.error(error);
        setError(error);
      }
    },
    [setError, setChannels, channels, pathUp]
  );

  const updateChannel = useCallback(
    async (channel) => {
      try {
        const { entityType } = channel;
        const fns = new Set(ENTITY_CONFIG[entityType].supportedFunctions);

        let fn;
        if (fns.has('update')) {
          fn = 'update';
        } else {
          throw new Error('No update function');
        }

        const [intg, type] = entityType.toLowerCase().split(':');
        const { payload } = await API.post(
          'admin',
          `/channel/${intg}/${type}/${fn}`,
          { body: channel }
        );
        setChannels(
          channels.map((c) => (c.entityId === channel.entityId ? payload : c))
        );
        pathUp();
      } catch (error) {
        console.error(error);
        setError(error);
      }
    },
    [setError, setChannels, channels, pathUp]
  );

  const getChannelByRef = useCallback(
    (channelRef) => {
      return channels.find((c) => c.entityRef === channelRef);
    },
    [channels]
  );

  const getChannelName = useCallback(
    (channelRef) => {
      const channel = channels.find((c) => c.entityRef === channelRef);
      if (channel) {
        return channel.body.name;
      }
    },
    [channels]
  );

  const requiresAuthByRef = useCallback(
    (channelRef) => {
      try {
        const channel = getChannelByRef(channelRef);
        return channel.body.useFacebookLogin ? true : false;
      } catch (e) {
        return false;
      }
    },
    [getChannelByRef]
  );

  const usesChannel = useCallback((entityType) => {
    const entityConfig = ENTITY_CONFIG[entityType];
    if (entityType && entityConfig) {
      return entityConfig.isSource || entityConfig.isDestination
        ? hasField(entityType, 'channelRef')
        : false;
    }
    return undefined;
  }, []);

  const ctx = useMemo(() => {
    return {
      channels,
      createChannel,
      updateChannel,
      requiresAuthByRef,
      getChannelByRef,
      getChannelName,
      usesChannel,
    };
  }, [
    channels,
    createChannel,
    updateChannel,
    requiresAuthByRef,
    getChannelByRef,
    getChannelName,
    usesChannel,
  ]);

  return (
    <ChannelContext.Provider value={ctx}>{children}</ChannelContext.Provider>
  );
};

export const ChannelOptions = (props) => {
  const { entityType } = props;
  let { channels } = useChannelContext();
  if (entityType) {
    const r = entityType.split(':')[0];
    channels = channels.filter((channel) => channel.entityType.startsWith(r));
  }
  return [
    <option key={0} value="">
      No Channel Selected
    </option>,
    ...channels.map((channel, i) => (
      <option key={i + 1} value={channel.entityRef}>
        {channel.body.name}
      </option>
    )),
  ];
};

export const SelectChannel = (props) => {
  const { getChannelName } = useChannelContext();
  const { setFields, entity, entityType } = props;

  const onChange = useCallback(
    (event) => {
      const { value } = event.target;
      setFields({
        channelRef: value,
        channelName: getChannelName(value),
        channelItemRef: '',
        channelItemName: '',
      });
    },
    [setFields, getChannelName]
  );

  const disabled = !!entity.entityId || !entityType;

  return (
    <FieldGrid name="Channel" isRequired={!entity.body.channelRef}>
      <select
        className="input-group-field"
        onChange={onChange}
        name="channelRef"
        value={entity.body.channelRef}
        disabled={disabled}
      >
        <ChannelOptions entityType={entityType} />
      </select>
    </FieldGrid>
  );
};

export default useChannelContext;
