export const amplifyErrorMessage = (error) => {
  console.error('AMPLIFY API ERROR:', error);
  let errorMessage;
  try {
    errorMessage = error.response.data.error;
  } catch (error) {
    console.error('AMPLIFY API MISSING ERROR MESSAGE');
    errorMessage = error.message;
  }
  return errorMessage;
};

export const amplifyErrorAction = ({ error, type = 'AMPLIFY_ERROR' }) => ({
  type,
  error,
  errorMessage: amplifyErrorMessage(error),
});

export const createAmplifyErrorAction = (type) => (error) =>
  amplifyErrorAction({ type, error });

// ffs... keyed on Symbol('topic')
//const topic = value[Object.getOwnPropertySymbols(value)[0]];
