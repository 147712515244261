import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';

import { Grid, GridContainer, Cell } from 'react-foundation';

import Welcome from './pages/Welcome';
import Bindings from './pages/Bindings';
import Graphs from './pages/Graphs';
import Channels from './pages/Channels';
import Countries from './pages/Countries';
import Documentation from './pages/Documentation';
import Account from './pages/Account';

import Menu from './App/Menu';
import Breadcrumbs from './App/Breadcrumbs';

export const AppContainer = () => {
  return (
    <GridContainer>
      <Grid>
        <Cell>
          <Menu />
          <Breadcrumbs />
          <Switch>
            <Route exact path="/" component={Welcome} />
            <Route path="/bindings" component={Bindings} />
            <Route path="/graphs" component={Graphs} />
            <Route path="/channels" component={Channels} />
            <Route path="/countries" component={Countries} />
            <Route path="/documentation" component={Documentation} />
            <Route path="/account" component={Account} />
          </Switch>
        </Cell>
      </Grid>
    </GridContainer>
  );
};

export default withRouter(AppContainer);
