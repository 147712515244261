import React from 'react';

import { BindingContextProvider } from './useBindingContext';
import { ErrorContextProvider } from '../../hooks/useErrorContext';
import Form from './Form';

export const BindingContainer = (props) => {
  const { binding } = props;

  return (
    <ErrorContextProvider>
      <BindingContextProvider binding={binding}>
        <Form />
      </BindingContextProvider>
    </ErrorContextProvider>
  );
};

export default BindingContainer;
