import { createReducer } from '../helpers/redux';
import { amplifyErrorMessage } from '../helpers/amplify';
import { pathUp } from '../../hooks/useNavigation';

import e from '../../rest/entities';

import b from '../../rest/bindings';

import { SIGNED_IN, SIGNED_OUT, MESSAGE_RECEIVED } from '../constants';

const BINDING_DELTA = 'broker/binding/DELTA';

const FETCH = 'messaging/binding/FETCH';
const FETCH_SUCCESS = 'messaging/binding/FETCH_SUCCESS';
const FETCH_FAILURE = 'messaging/binding/FETCH_FAILURE';

const CREATE = 'messaging/binding/CREATE';
const CREATE_SUCCESS = 'messaging/binding/CREATE_SUCCESS';
const CREATE_FAILURE = 'messaging/binding/CREATE_FAILURE';

const UPDATE = 'messaging/binding/UPDATE';
const UPDATE_SUCCESS = 'messaging/binding/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'messaging/binding/UPDATE_FAILURE';

const IMPORT = 'messaging/binding/IMPORT';
const IMPORT_SUCCESS = 'messaging/binding/IMPORT_SUCCESS';
const IMPORT_FAILURE = 'messaging/binding/IMPORT_FAILURE';

const DELETE = 'messaging/binding/DELETE';
const DELETE_SUCCESS = 'messaging/binding/DELETE_SUCCESS';
const DELETE_FAILURE = 'messaging/binding/DELETE_FAILURE';

// State
const initialState = {
  bindings: [],
  inProgress: false,
};

// Actions
export const createBinding = (binding) => async (dispatch, getState) => {
  let { source, destination } = binding;
  dispatch({ type: CREATE });

  try {
    if (source && !source.entityId) {
      binding.source = await e.createSource(source);
    }

    if (!destination.entityId) {
      binding.destination = await e.createDestination(destination);
    }

    const newBinding = await b.createBinding(binding);

    newBinding.source = source;
    newBinding.destination = destination;

    dispatch({ type: CREATE_SUCCESS, binding: newBinding });
  } catch (error) {
    dispatch({ type: CREATE_FAILURE, errorMessage: error });
    return;
  }

  pathUp(dispatch, getState);
};

export const deleteBinding = (binding) => async (dispatch, getState) => {
  const { bindingId } = binding;
  dispatch({ type: DELETE });

  try {
    await b.deleteBinding(bindingId);
    dispatch({ type: DELETE_SUCCESS, bindingId });
  } catch (error) {
    const errorMessage = error;
    dispatch({ type: DELETE_FAILURE, error, errorMessage });
    return;
  }

  pathUp(dispatch, getState);
};

export const updateBinding = (binding) => async (dispatch, getState) => {
  dispatch({ type: UPDATE });

  try {
    const newBinding = await b.updateBinding(binding);
    dispatch({ type: UPDATE_SUCCESS, binding: newBinding });
    pathUp(dispatch, getState);
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    dispatch({ type: UPDATE_FAILURE, error, errorMessage });
  }
};

export const fetchBindings = () => async (dispatch) => {
  dispatch({ type: FETCH });

  try {
    const bindings = await b.fetchBindings();
    dispatch({ type: FETCH_SUCCESS, bindings });
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    dispatch({ type: FETCH_FAILURE, error, errorMessage });
  }
};

const reduceCountsActions = (a, v) => {
  const prev = a.actions.find(({ actionType }) => actionType === v.actionType);
  if (prev) {
    a.total += v.total - prev.total;
    a.errors += v.errors - prev.errors;
    prev.total = v.total;
    prev.errors = v.errors;
  } else {
    a.actions.push(v);
    a.total += v.total;
    a.errors += v.errors;
  }
  return a;
};

const reduceStatistics = (a, { date, total, errors }) => {
  const prev = a.find(({ date: d }) => d === date);
  if (prev) {
    prev.total = total;
    prev.errors = errors;
  } else {
    a.push({ date, total, errors });
  }
  return a;
};

const reduceMessageReceived = (state, { topic, value }) => {
  const [purpose, shortRef] = topic.split('/').slice(2);
  if (purpose === 'actions' && value.type === BINDING_DELTA) {
    return {
      ...state,
      bindings: state.bindings.map((b) => {
        if (b.shortRef === shortRef) {
          const { delta } = value;
          const newCounts = value.delta.counts.actions.reduce(
            reduceCountsActions,
            {
              actions: [...b.counts.actions],
              total: b.counts.total,
              errors: b.counts.errors,
            }
          );
          const newStats = delta.statistics.reduce(reduceStatistics, [
            ...b.statistics,
          ]);
          return {
            ...b,
            counts: newCounts,
            statistics: newStats,
          };
        } else {
          return b;
        }
      }),
    };
  } else {
    return state;
  }
};

// Listeners
export const listeners = {
  [SIGNED_IN]: fetchBindings,
};

// Reducers

const errorReducer = (state, { errorMessage }) => ({
  ...state,
  inProgress: false,
  errorMessage,
});

const startReducer = (state) => ({
  ...state,
  inProgress: true,
  errorMessage: null,
});

export default createReducer(initialState, {
  [FETCH_SUCCESS]: (state, { bindings }) => ({ ...state, bindings }),
  [CREATE_SUCCESS]: (state, { binding }) => ({
    ...state,
    bindings: [...state.bindings, binding],
    inProgress: false,
  }),
  [UPDATE_SUCCESS]: (state, { binding }) => ({
    ...state,
    bindings: state.bindings.map((b) =>
      b.bindingId === binding.bindingId ? { ...binding } : b
    ),
    inProgress: false,
  }),
  [CREATE_FAILURE]: errorReducer,
  [UPDATE_FAILURE]: errorReducer,
  [CREATE]: startReducer,
  [UPDATE]: startReducer,

  [IMPORT_SUCCESS]: (state, { json }) => ({
    ...state,
    bindings: [...state.bindings, json.payload],
    inProgress: false,
  }),
  [IMPORT_FAILURE]: errorReducer,
  [IMPORT]: startReducer,

  [DELETE_SUCCESS]: (state, { bindingId }) => ({
    ...state,
    bindings: state.bindings.filter((b) => b.bindingId !== bindingId),
    inProgress: false,
  }),
  [DELETE_FAILURE]: errorReducer,
  [DELETE]: startReducer,

  [MESSAGE_RECEIVED]: reduceMessageReceived,

  // External
  [SIGNED_OUT]: (state, action) => initialState,
  [SIGNED_IN]: (state, action) => initialState,
});

// Selectors
export const getBindings = (state) => state.bindings.bindings;
export const inProgress = (state) => state.bindings.inProgress;
export const createError = (state) => state.bindings.errorMessage;
export const updateError = (state) => state.bindings.errorMessage;
export const errorMessage = (state) => state.bindings.errorMessage;
export const getSettings = (state) => state.bindings.settings;
