import React from 'react';
import { MediaObject, MediaObjectSection } from 'react-foundation';

import Icon from './Icon';

import {
  FACEBOOK,
  ACTIONKIT,
  CORE_WEBHOOK,
  CORE_COUNTER,
  TEXTIT,
  FACEBOOK_PAGE_FEED,
} from './constants';

const Desc = ({ name, entityType, subtitle }) => {
  return (
    <MediaObject style={{ margin: 0 }}>
      <MediaObjectSection style={{ fontSize: '250%' }}>
        <Icon entityType={entityType} />
      </MediaObjectSection>
      <MediaObjectSection isMain>
        <strong>{name}</strong>
        <br /> {subtitle}
      </MediaObjectSection>
    </MediaObject>
  );
};

const getEntityName = (entity) => {
  const { entityType, entityRef } = entity;
  const [intg] = entityType.split(':');

  if (entityType === CORE_WEBHOOK) {
    return entity.body.url.split('/').slice(0, 3).join('/');
  }

  if (entityType === CORE_COUNTER) {
    return '';
  }

  if (entityType.split(':')[1] === 'Endpoint') {
    return entityRef;
  }

  if (intg === ACTIONKIT) {
    return entity.body.name;
  }

  if (intg === TEXTIT) {
    return entity.body.name;
  }

  if (intg === FACEBOOK) {
    return entity.body.channelItemName;
  }

  return entityRef;
};

const getEntitySubtitle = (entity) => {
  const { entityType } = entity;
  if (entityType === FACEBOOK_PAGE_FEED) {
    return entity.body.channelName;
  } else {
    return entityType.split(':').slice(1).join(':');
  }
};

export const ListDesc = ({ entity }) => {
  const { entityType } = entity;
  const name = getEntityName(entity);
  const subtitle = getEntitySubtitle(entity);

  return <Desc entityType={entityType} name={name} subtitle={subtitle} />;
};

export default ListDesc;
