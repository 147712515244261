import React, { useMemo, useCallback } from 'react';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import { Callout } from 'react-foundation';

import useBindingContext from './useBindingContext';
import useChannelContext from '../Entity/useChannelContext';
import useConfigContext from '../../hooks/useConfigContext';

import { ENTITY_CONFIG } from '@one/entity-helper';
import config from '../../config';

export const BindingInfoContainer = (props) => {
  const [binding] = useBindingContext();
  const { brokerConfig } = useConfigContext();
  const { getChannelByRef } = useChannelContext();
  const { sourceType } = binding;

  const template = useMemo(() => {
    const infoSnippet = ENTITY_CONFIG[sourceType].infoSnippet;
    return infoSnippet || '';
  }, [sourceType]);

  const compileTemplate = useCallback(
    (template) => {
      try {
        const { channelRef } = binding.source.body;
        const channel = channelRef ? getChannelByRef(channelRef) : {};
        return Handlebars.compile(template)({
          binding,
          config,
          channel,
          ...(brokerConfig || {}),
        });
      } catch (error) {
        console.error(error);
        return template;
      }
    },
    [binding, getChannelByRef, brokerConfig]
  );

  const compiled = useMemo(
    () => compileTemplate(template),
    [template, compileTemplate]
  );

  return <Callout dangerouslySetInnerHTML={{ __html: compiled }} />;
};

export default BindingInfoContainer;
