import React, {
  useContext,
  useMemo,
  createContext,
  useState,
  useEffect,
} from 'react';

import { Callout, Colors } from 'react-foundation';
import { getLocation, getHash } from 'connected-react-router';
import { useSelector } from 'react-redux';

export const ErrorContext = createContext();

export const useErrorContext = () => useContext(ErrorContext);

const DisplayError = (error) => {
  console.log('Error Context Display:', typeof error, error);
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object') {
    if (error.error) {
      return <p>{String(error.error)}</p>;
    } else {
      return <pre>{JSON.stringify(error, null, 2)}</pre>;
    }
  }
  return String(error);
};

export const ErrorContextProvider = ({ children, message }) => {
  const [error, setError] = useState();
  const { pathname } = useSelector(getLocation);
  const { hash } = useSelector(getHash);

  useEffect(() => setError(message), [message]);
  useEffect(() => setError(false), [pathname, hash]);

  const value = useMemo(() => ({ setError }), [setError]);

  return (
    <ErrorContext.Provider value={value}>
      {error && (
        <Callout color={Colors.ALERT}>
          <DisplayError error={error} />
        </Callout>
      )}
      {children}
    </ErrorContext.Provider>
  );
};

export default useErrorContext;
