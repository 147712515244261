import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  CORE_DEFAULT_SOURCE,
  CORE_DEFAULT_DESTINATION,
  CORE_DEFAULT_ACTION,
  CORE_DEFAULT_TRIGGER,
} from '../Entity/constants';

import { useEntityApi, useVirtualEntityApi } from '../Entity/useEntityApi';

import {
  updateBinding,
  createBinding,
  errorMessage,
  deleteBinding,
} from '../../redux/modules/bindings';

import useErrorContext from '../../hooks/useErrorContext';

import useSimpleApi from '../../hooks/useSimpleApi';

export const BindingContext = createContext();

export const useBindingState = () => useContext(BindingContext);
export const useBindingContext = () => useContext(BindingContext);

export const BindingContextProvider = (props) => {
  const { children, binding } = props;

  const initialState = useMemo(
    () => ({
      name: '',
      source: {
        entityType: CORE_DEFAULT_SOURCE,
        selectedFunction: 'create',
        body: {},
      },
      destination: {
        entityType: CORE_DEFAULT_DESTINATION,
        selectedFunction: 'create',
        body: {},
      },
      actionType: CORE_DEFAULT_ACTION,
      action: {},
      triggerType: CORE_DEFAULT_TRIGGER,
      trigger: {},
    }),
    []
  );

  const [bindingState, bindingApi] = useSimpleApi(initialState);

  const [source, sourceApi] = useEntityApi(initialState.source);
  const [destination, destinationApi] = useEntityApi(initialState.destination);

  const [actionState, actionApi] = useVirtualEntityApi({
    type: initialState.actionType,
    data: initialState.action,
  });

  const [triggerState, triggerApi] = useVirtualEntityApi({
    type: initialState.triggerType,
    data: initialState.trigger,
  });

  const setState = useCallback(
    (state) => {
      bindingApi.setState(state);
      sourceApi.setState(state.source);
      destinationApi.setState(state.destination);
      actionApi.setState({ type: state.actionType, data: state.action || {} });
      triggerApi.setState({
        type: state.triggerType || initialState.triggerType,
        data: state.trigger || {},
      });
    },
    [
      sourceApi,
      destinationApi,
      actionApi,
      triggerApi,
      bindingApi,
      initialState.triggerType,
    ]
  );

  const dispatch = useDispatch();

  const crud = useMemo(
    () => ({
      update: (binding) => dispatch(updateBinding(binding)),
      create: (binding) => dispatch(createBinding(binding)),
      reset: () => setState(binding || initialState),
      delete: () => dispatch(deleteBinding(binding)),
    }),
    [setState, binding, initialState, dispatch]
  );

  const { setError } = useErrorContext();
  const err = useSelector(errorMessage);

  useEffect(() => {
    if (err) {
      setError(err);
    } else {
      setError(false);
    }
  }, [setError, err]);

  const api = useMemo(
    () => ({
      source: sourceApi,
      destination: destinationApi,
      action: actionApi,
      trigger: triggerApi,
      setField: bindingApi.setField,
      setState,
      crud,
      binding,
      initialState,
    }),
    [
      sourceApi,
      destinationApi,
      actionApi,
      triggerApi,
      setState,
      bindingApi.setField,
      crud,
      binding,
      initialState,
    ]
  );

  const state = useMemo(() => {
    return {
      ...bindingState,
      source,
      destination,
      action: actionState.data,
      actionType: actionState.type,
      trigger: triggerState.data,
      triggerType: triggerState.type,
    };
  }, [source, destination, actionState, triggerState, bindingState]);

  useEffect(() => {
    if (binding) {
      setState(binding);
    }
  }, [binding, setState]);

  return (
    <BindingContext.Provider value={[state, api]}>
      {children}
    </BindingContext.Provider>
  );
};

export default useBindingContext;
