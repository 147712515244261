import React, { useCallback } from 'react';

import Field from './Field';

export const FieldsContainer = (props) => {
  const { values, fields, disabled, setFields, onChange, dfns } = props;

  const onChangeHandler = useCallback(
    (event) => {
      if (onChange) {
        onChange(event);
      } else if (setFields) {
        const { name, value } = event.target;
        setFields({ [name]: value });
      } else {
        console.error('no handler for:', event);
      }
    },
    [onChange, setFields]
  );

  return (
    <div>
      {fields.map((dfn, i) => {
        if (dfns && dfns[dfn.name]) {
          dfn = { ...dfn, ...dfns[dfn.name] };
        }
        return (
          <Field
            onChange={onChangeHandler}
            dfn={dfn}
            key={i}
            value={values[dfn.name]}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default FieldsContainer;
