import React, { useContext, createContext } from 'react';

import useEntityApi from './useEntityApi';

export const EntityContext = createContext();

export const useEntityContext = () => useContext(EntityContext);

export const EntityContextProvider = ({ children, initialState }) => {
  const [entity, api] = useEntityApi(initialState);

  return (
    <EntityContext.Provider value={{ api, entity }}>
      {children}
    </EntityContext.Provider>
  );
};

export default useEntityContext;
