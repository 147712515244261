import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Auth, Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { updateAuthState, signedIn } from '../redux/modules/auth';
import { ConfigContextProvider } from '../hooks/useConfigContext';
import App from './App';

const AppWithAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      dispatch(updateAuthState(event));
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => dispatch(signedIn(currentUser)))
      .catch(() => console.log('Not signed in'));

    return unsubscribe;
  }, [dispatch]);

  return (
    <Authenticator
      hideSignUp={true}
      socialProviders={['google']}
      variation={'modal'}
    >
      <ConfigContextProvider>
        <App />
      </ConfigContextProvider>
    </Authenticator>
  );
};

export default AppWithAuth;
