import { PubSub, API } from 'aws-amplify';

import { createAction, createReducer, constantNamer } from '../helpers/redux';

import { SIGNED_IN, SIGNED_OUT, MESSAGE_RECEIVED } from '../constants';

const namer = constantNamer('pubsub');

const SENT = namer('SENT');
const SEND_ERROR = namer('SEND_ERROR');

const RECEIVED = MESSAGE_RECEIVED;
const CONNECTION_ERROR = namer('CONNECTION_ERROR');

const SUBSCRIBED = namer('SUBSCRIBED');
export const PREPARED = namer('PREPARED');
const CONNECTION_CLOSED = namer('CONNECTION_CLOSED');

const CLIENT_TOPIC = 'messaging/prod/clients';

// State
const initialState = {};

// Actions
const sent = createAction(SENT, 'topic', 'message');
const sendError = createAction(SEND_ERROR, 'error');
export const send = (topic, message) => async (dispatch) => {
  try {
    await PubSub.publish(topic, message);
    dispatch(sent(topic, message));
  } catch (error) {
    dispatch(sendError(error));
  }
};

const received = createAction(RECEIVED, 'subscriptionTopic', 'topic', 'value');
const connectionError = createAction(CONNECTION_ERROR, 'error');
const connectionClosed = createAction(CONNECTION_CLOSED, 'closed');
const subscribed = createAction(SUBSCRIBED, 'subscription');
export const subscribe = (topic) => async (dispatch, getState) => {
  try {
    if (!getSubscriptions(getState())[topic]) {
      const subscription = PubSub.subscribe(topic).subscribe({
        next: ({ value }) => {
          // Message topic keyed on Symbol('topic')
          const messageTopic = value[Object.getOwnPropertySymbols(value)[0]];
          return dispatch(received(topic, messageTopic, value));
        },
        error: (error) => dispatch(connectionError(error)),
        close: () => dispatch(connectionClosed()),
      });
      dispatch(subscribed(subscription));
    }
    dispatch(send(CLIENT_TOPIC, { message: 'subscribed!' }));
  } catch (error) {
    console.error(error);
  }
};

const prepared = createAction(PREPARED, 'payload');
const prepare = () => async (dispatch) => {
  try {
    const { payload } = await API.get('admin', `/pubsub/prepare`);
    dispatch(prepared(payload));
  } catch (error) {
    console.error('PubSub prepare failed', error);
  }
};

// Listeners
export const listeners = {
  [SIGNED_IN]: prepare,
};

// Reducers
export default createReducer(initialState, {
  [SUBSCRIBED]: (state, { topic, subscription }) => ({
    ...state,
    topic: subscription,
  }),

  // External
  [SIGNED_OUT]: () => initialState,
  [SIGNED_IN]: () => initialState,
});

// Selectors
export const getSubscriptions = (state) => state.pubsub;
