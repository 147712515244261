import Amplify from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import { debug, aws_exports, region, awsIotEnpoint } from './config';

const awspubsub = {
  aws_pubsub_region: region,
  aws_pubsub_endpoint: `wss://${awsIotEnpoint}/mqtt`,
};

Amplify.configure(aws_exports);

if (debug) {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

Amplify.addPluggable(new AWSIoTProvider(awspubsub));
