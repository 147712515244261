import React, { useCallback, useEffect, useState } from 'react';
import { isSupported } from '@one/entity-helper/lib';

import useErrorContext from '../../hooks/useErrorContext';
import { FieldGrid } from '../Fields';
import Or from '../Or';

import { fetchSources, fetchDestinations } from '../../rest/entities';

export const EntityOption = ({ entity }) => {
  const { channelName, channelItemName, name } = entity.body;
  let outputName = channelItemName || name || entity.entityRef;

  if (channelName) {
    outputName = `${outputName} (${channelName})`;
  }

  return <option value={entity.entityId}>{outputName}</option>;
};

export const FindExisting = (props) => {
  const { purpose, state, setState, initialState, disabled } = props;

  const [entities, setEntities] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setError } = useErrorContext();
  const { entityType } = state;
  const { channelRef } = state.body;

  useEffect(() => {
    if (disabled) {
      setEntities([]);
    } else if (isSupported(entityType, 'find')) {
      let p, noOp;
      setLoading(true);
      switch (purpose) {
        case 'source':
          p = fetchSources(entityType, channelRef);
          break;
        case 'destination':
          p = fetchDestinations(entityType, channelRef);
          break;
        default:
          throw new Error(`Unrecognised purpose ${purpose}`);
      }
      p.then((e) => noOp || setEntities(e) + setLoading(false)).catch(
        (e) => noOp || setLoading(false)
      );
      return () => (noOp = true);
    } else {
      setEntities(false);
    }
  }, [
    setEntities,
    entityType,
    setError,
    purpose,
    setLoading,
    channelRef,
    disabled,
  ]);

  const onChange = useCallback(
    (event) => {
      const entityId = event.target.value;
      if (disabled) {
        // do nothing
      } else if (entityId) {
        const newEntity = entities.find((e) => e.entityId === entityId);
        if (newEntity) {
          setState(newEntity);
        }
      } else if (entityType) {
        setState({ ...initialState, entityType });
      }
    },
    [setState, entities, entityType, initialState, disabled]
  );

  if (!entities && !loading) {
    return null;
  }

  return (
    <div>
      <FieldGrid name={`Existing ${purpose}`}>
        <select
          className="input-group-field"
          onChange={onChange}
          name={`${purpose}.entityId`}
          value={state.entityId}
          disabled={disabled}
        >
          {loading ? (
            <option value="">Loading...</option>
          ) : entities.length ? (
            <option value="">No {purpose} selected</option>
          ) : (
            <option value="">No {purpose}s found</option>
          )}
          {entities
            ? entities.map((e, i) => <EntityOption key={i} entity={e} />)
            : null}
        </select>
      </FieldGrid>
      <Or />
    </div>
  );
};

export default FindExisting;
