import React, { useState, useEffect, useCallback } from 'react';

import {
  Pagination,
  PaginationPrevious,
  PaginationNext,
  PaginationItem,
  PaginationEllipsis,
  // Link
} from 'react-foundation';

// Need a way to make this component navigable via the router
// probably using the query.

const ITEMS_PER_PAGE = 25;

function PaginatedSearch(props) {
  const { getItems, children } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [pagesSoFar, setPagesSoFar] = useState(1);
  const [nextToken, setNextToken] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    const [newItems, newNextToken] = await getItems({ nextToken });
    let combinedPagesSoFar = pagesSoFar;
    if (newItems) {
      const combinedItems = [...items, ...newItems];
      setItems(combinedItems);
      combinedPagesSoFar = Math.ceil(combinedItems.length / ITEMS_PER_PAGE);
      setPagesSoFar(combinedPagesSoFar);
    }
    if (newNextToken) {
      setNextToken(newNextToken);
    } else {
      setLastPage(combinedPagesSoFar);
    }
    setIsFetching(false);
  }, [getItems, items, nextToken, pagesSoFar]);

  useEffect(() => {
    if (lastPage === 0 && !isFetching) {
      if (currentPage >= pagesSoFar - 2) {
        fetchData();
      }
    }
  }, [currentPage, pagesSoFar, isFetching, fetchData, lastPage]);

  const itemsToRender = items.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  const pagesToLink =
    pagesSoFar > 5
      ? Array.from(Array(pagesSoFar + 1).keys()).slice(
          currentPage - 2 > 1 ? currentPage - 2 : 1,
          currentPage + 3
        )
      : Array.from(Array(pagesSoFar + 1).keys()).slice(1);

  return (
    <div>
      <Pagination>
        <PaginationPrevious isDisabled={isFirstPage}>
          {isFirstPage ? (
            <span>Previous</span>
          ) : (
            <a
              href={`#${currentPage - 1}`}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </a>
          )}
        </PaginationPrevious>
        {pagesToLink[0] === 1 ? null : <PaginationEllipsis />}
        {pagesToLink.map((p) => (
          <PaginationItem
            {...{
              isCurrent: p === currentPage,
              key: p,
            }}
          >
            <a href={`#${p}`} onClick={() => setCurrentPage(p)}>
              {p}
            </a>
          </PaginationItem>
        ))}
        {lastPage && lastPage !== pagesToLink[-1] ? null : (
          <PaginationEllipsis />
        )}
        <PaginationNext isDisabled={isLastPage}>
          {isLastPage ? (
            <span>Next</span>
          ) : (
            <a
              href={`#${currentPage + 1}`}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </a>
          )}
        </PaginationNext>
      </Pagination>
      {children({ items: itemsToRender })}
    </div>
  );
}

export default PaginatedSearch;
