import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserInfo } from '../../redux/modules/auth';

import {
  useFacebookContext,
  LogOutButton,
} from '../../hooks/useFacebookContext';

export const FacebookAccount = (props) => {
  const [info, setInfo] = useState({});
  const { appId, getUserInfo, isReady } = useFacebookContext();

  useEffect(() => {
    if (getUserInfo) {
      getUserInfo().then(setInfo);
    }
  }, [getUserInfo]);

  if (isReady) {
    return (
      <div>
        <hr />
        <h4>Facebook</h4>
        <dl>
          <dt>Name</dt>
          <dd>{info.name}</dd>
          <dt>User Id</dt>
          <dd>{info.id}</dd>
          <dt>App Id</dt>
          <dd>{appId}</dd>
        </dl>
        <LogOutButton />
      </div>
    );
  } else {
    return '';
  }
};

export const Account = (props) => {
  const oauthInfo = useSelector(getUserInfo);

  return (
    <div>
      <h3>Account</h3>
      <hr />
      <h4>ONE</h4>
      <dl>
        <dt>Name</dt>
        <dd>{oauthInfo.name}</dd>
        <dt>Email</dt>
        <dd>{oauthInfo.email}</dd>
      </dl>
      <FacebookAccount />
    </div>
  );
};

export default Account;
