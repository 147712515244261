import React from 'react';
import { Link } from 'react-router-dom';
import { Callout } from 'react-foundation';

import * as C from './constants';

const descriptions = {
  [C.CORE_ENDPOINT]: {
    short:
      'An endpoint that accepts incoming messages over a number of channels.',
  },
  [C.CORE_WEBHOOK]: {
    short: 'A remote system endpoint.',
  },
  [C.ACTIONKIT_PETITIONPAGE]: {
    short: 'An imported an Action Kit petition page.',
  },
  [C.ACTIONKIT_SIGNUPPAGE]: {
    short: 'An imported an Action Kit sign up page.',
  },
  [C.FACEBOOK_PAGE_FEED]: {
    short: () => (
      <div>
        <Name>{C.FACEBOOK_PAGE_FEED}</Name>
        <Definition>
          Facebook page feed webhook. This requires a{' '}
          <Link to="/channels">Facebook Channel</Link> to be configured which is
          connected to a Facebook App.
        </Definition>
      </div>
    ),
  },
  [C.FACEBOOK_PAGE_FEED_TEST]: {
    short: 'Facebook Page Feed Test webhook trigger.',
  },
  [C.FACEBOOK_PAGE_FEED_COMMENT]: {
    short: 'Facebook Page Feed Comment webhook trigger.',
  },
  [C.FACEBOOK_PAGE_FEED_STATUS]: {
    short: 'Facebook Page Feed Status webhook trigger.',
  },
  [C.CORE_WEBHOOK_GET]: {
    short: 'Fires a GET request to a destination endpoint.',
  },
  [C.CORE_WEBHOOK_POST]: {
    short: 'Fires a templated POST request to a destination endpoint.',
  },
  [C.TEXTIT_FLOW]: {
    short: 'Interacts with a messaging flow on the TextIt platform.',
  },
  [C.TEXTIT_FLOW_START_MESSENGER]: {
    short: 'Starts a flow as a Messenger user on the TextIt platform.',
  },
};

const Name = ({ children }) => {
  return <b>{children}</b>;
};

const Definition = ({ children }) => {
  return <p>{children}</p>;
};

export const Description = ({ entityType }) => {
  const { short } = descriptions[entityType] || {
    short: () => (
      <p>
        No description for <i>{entityType}</i>
      </p>
    ),
  };

  return typeof short === 'string' ? (
    <Callout>
      <Name>{entityType}</Name>
      <Definition>{short}</Definition>
    </Callout>
  ) : (
    <Callout>{short()}</Callout>
  );
};

export default Description;
