import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Icon, Cell, Grid } from 'react-foundation';
import { Switch, Route, Link } from 'react-router-dom';

import {
  ChannelContextProvider,
  useChannelContext,
} from '../../components/Entity/useChannelContext';

import EntityType from '../../components/Entity/Type';
import Fields from '../../components/Entity/Fields';

import {
  useEntityContext,
  EntityContextProvider,
} from '../../components/Entity/useEntityContext';

import { ErrorContextProvider } from '../../hooks/useErrorContext';

import { DEFAULT_CHANNEL } from '../../components/Entity/constants';
import { EntityIcon } from '../../components/Entity/Icon';

const initialState = {
  entityType: DEFAULT_CHANNEL,
  body: {},
};

const iconStyle = { fontSize: 54, width: '0.75em', height: '0.75em' };

const ListRow = ({ channel }) => {
  const [imgSrc, setImgSrc] = useState(channel.body.imgSrc || false);

  return (
    <tr>
      <td>
        <EntityIcon entityType={channel.entityType} {...iconStyle} />
      </td>
      <td>
        {imgSrc && (
          <img
            src={imgSrc}
            alt="channel"
            style={iconStyle}
            onError={setImgSrc(false)}
          />
        )}
        {imgSrc || <Icon name="fi-photo" style={iconStyle} />}
      </td>
      <td>
        <Link to={`/channels/${channel.entityId}`}>{channel.body.name}</Link>
        <br />
        {channel.entityRef}
      </td>
      <td>
        {channel.body.verifiedAt && (
          <Icon name="fi-checkbox" style={iconStyle} />
        )}
      </td>
    </tr>
  );
};

export const List = ({ history }) => {
  const { channels } = useChannelContext();
  const goCreate = useCallback(() => {
    history.push('/channels/create');
  }, [history]);

  return (
    <div>
      <Button onClick={goCreate}>Create Channel</Button>
      <table>
        <thead>
          <tr>
            <th colSpan={3}>Name</th>
            <th>Verified</th>
          </tr>
        </thead>
        <tbody>
          {channels.map((channel) => (
            <ListRow key={channel.entityId} channel={channel} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const Form = ({ handleReset }) => {
  const { entity, api } = useEntityContext();
  const { setEntityType, setFields } = api;
  const { createChannel, updateChannel } = useChannelContext();
  const isEdit = !!entity.entityId;

  const handleCreate = useCallback(
    (event) => {
      event.preventDefault();
      createChannel(entity);
    },
    [entity, createChannel]
  );

  const handleUpdate = useCallback(
    (event) => {
      event.preventDefault();
      updateChannel(entity);
    },
    [entity, updateChannel]
  );

  return (
    <form>
      <EntityType
        entity={entity}
        purpose="channel"
        setEntityType={setEntityType}
      />
      <Fields entity={entity} setFields={setFields} />
      <Grid>
        <Cell small={3} offsetOnSmall={9}>
          <ButtonGroup isExpanded>
            {handleReset && <Button onClick={handleReset}>Reset</Button>}
            {isEdit ? (
              <Button onClick={handleUpdate}>Update</Button>
            ) : (
              <Button onClick={handleCreate}>Create</Button>
            )}
          </ButtonGroup>
        </Cell>
      </Grid>
    </form>
  );
};

export const Edit = ({ history }) => {
  const { channels } = useChannelContext();
  const { api } = useEntityContext();
  const { setState } = api;

  const [entityId] = history.location.pathname.split('/').slice(-1);

  useEffect(
    () => setState(channels.find((c) => c.entityId === entityId)),
    [setState, entityId, channels]
  );

  const handleReset = useCallback(
    (event) => {
      event.preventDefault();
      setState(channels.find((c) => c.entityId === entityId));
    },
    [setState, channels, entityId]
  );

  return <Form handleReset={handleReset} />;
};

export const Create = () => {
  const { api } = useEntityContext();
  const { setState } = api;

  useEffect(() => setState(initialState), [setState]);

  const handleReset = useCallback(
    (event) => {
      event.preventDefault();
      setState(initialState);
    },
    [setState]
  );

  return <Form handleReset={handleReset} />;
};

export const ChannelsContainer = () => {
  return (
    <div>
      <h3>Channels</h3>
      <hr />
      <ErrorContextProvider>
        <ChannelContextProvider>
          <EntityContextProvider initialState={initialState}>
            <Switch>
              <Route exact path="/channels" component={List} />
              <Route path="/channels/create" component={Create} />
              <Route path="/channels" component={Edit} />
            </Switch>
          </EntityContextProvider>
        </ChannelContextProvider>
      </ErrorContextProvider>
    </div>
  );
};

export default ChannelsContainer;
