import { getListeners } from '../modules/listeners';

import { debug } from '../../config';

export default ({ dispatch, getState }) => {
  return (next) => (action) => {
    const rv = next(action);

    if (typeof action === 'object') {
      const state = getState();
      const listeners = getListeners(state, action.type) || [];

      if (debug) {
        console.log('ACTION', action);
        console.log('STATE', state);
        console.log('LISTENERS', listeners);
      }

      for (const listener of listeners) {
        dispatch(listener());
      }
    }

    return rv;
  };
};
