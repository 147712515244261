import React, { useState, useEffect, useCallback } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Switch, Route, Link } from 'react-router-dom';
import {
  Cell,
  Grid,
  Tabs,
  TabItem,
  TabsContent,
  TabPanel,
} from 'react-foundation';

import { getCountry as getCountryQuery } from '../../graphql/queries';
import {
  updateCountryName as updateCountryNameMutation,
  updateOfficialCountryName as updateOfficialCountryNameMutation,
  updateCountryIntgKey as updateCountryIntgKeyMutation,
} from '../../graphql/mutations';

import IntegrationKey from './IntegrationKey';
import Translations from './Translations';
import { FlagCell } from './Flag';
import ListRegions from './ListRegions';

const CountryGeneral = (props) => {
  const { country = {} } = props;
  const {
    regionTerm = '',
    region = '',
    subregion = '',
    languages = [],
  } = country;

  return (
    <div>
      <Grid className="input-group">
        <Cell small={2} className="input-group-label">
          Region
        </Cell>
        <Cell small={10}>
          <input
            className="input-group-field"
            type="text"
            placeholder="regionTerm"
            value={region}
            readOnly
          />
        </Cell>
      </Grid>
      <Grid className="input-group">
        <Cell small={2} className="input-group-label">
          Sub Region
        </Cell>
        <Cell small={10}>
          <input
            className="input-group-field"
            type="text"
            placeholder="regionTerm"
            value={subregion}
            readOnly
          />
        </Cell>
      </Grid>
      <Grid className="input-group">
        <Cell small={2} className="input-group-label">
          Langages
        </Cell>
        <Cell small={10}>
          <input
            className="input-group-field"
            type="text"
            placeholder="regionTerm"
            value={languages.join(', ')}
            readOnly
          />
        </Cell>
      </Grid>
      <Grid className="input-group">
        <Cell small={2} className="input-group-label">
          Region Term
        </Cell>
        <Cell small={10}>
          <input
            className="input-group-field"
            type="text"
            placeholder="regionTerm"
            value={regionTerm}
            readOnly
          />
        </Cell>
      </Grid>
    </div>
  );
};

export const Country = (props) => {
  const { countryCode, getRegionPath } = props;

  const [country, setCountry] = useState({});

  const getCountry = useCallback(async () => {
    const { data } = await API.graphql(
      graphqlOperation(getCountryQuery, { id: countryCode })
    );
    const { getCountry: newCountry = {} } = data;
    setCountry(newCountry);
  }, [countryCode]);

  const updateCountryName = useCallback(
    async (input) => {
      const { data } = await API.graphql(
        graphqlOperation(updateCountryNameMutation, { input })
      );
      const { updateCountryName: newCountryName = {} } = data;
      setCountry({ ...country, name: newCountryName });
      return newCountryName;
    },
    [country]
  );

  const updateOfficialCountryName = useCallback(
    async (input) => {
      const { data } = await API.graphql(
        graphqlOperation(updateOfficialCountryNameMutation, { input })
      );
      const { updateOfficialCountryName: newOfficialCountryName = {} } = data;
      setCountry({ ...country, official: newOfficialCountryName });
      return newOfficialCountryName;
    },
    [country]
  );

  const updateCountryIntgKey = useCallback(
    async (input) => {
      const { data } = await API.graphql(
        graphqlOperation(updateCountryIntgKeyMutation, { input })
      );
      const { updateCountryIntgKey: intgKey = {} } = data;
      const { country: c, ...newCountryIntgKey } = intgKey;
      setCountry({ ...country, integrationKey: newCountryIntgKey });
      return newCountryIntgKey;
    },
    [country]
  );

  useEffect(() => {
    getCountry();
  }, [getCountry]);

  const engName = country.name ? JSON.parse(country.name.name).eng : '';
  const countryPath = `/countries/${countryCode}`;

  const c = country;
  return (
    <div>
      <Grid>
        <Cell small={2}>
          <Grid vertical gridFrame="all">
            <FlagCell {...{ countryCode }} />
          </Grid>
        </Cell>
        <Cell small={10}>
          <Grid className="input-group">
            <Cell small={2} className="input-group-label">
              Country Code
            </Cell>
            <Cell small={10}>
              <input
                className="input-group-field"
                type="text"
                placeholder="countryCode"
                value={c.id || ''}
                readOnly
              />
            </Cell>
          </Grid>
          <Grid className="input-group">
            <Cell small={2} className="input-group-label">
              Name
            </Cell>
            <Cell small={10}>
              <input
                className="input-group-field"
                type="text"
                placeholder="Name"
                value={engName}
                readOnly
              />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
      <Tabs>
        <TabItem>
          <Link to={countryPath}>General</Link>
        </TabItem>
        <TabItem>
          <Link to={countryPath + '/name'}>Name</Link>
        </TabItem>
        <TabItem>
          <Link to={countryPath + '/official'}>Official Name</Link>
        </TabItem>
        <TabItem>
          <Link to={countryPath + '/regions'}>Regions</Link>
        </TabItem>
        <TabItem>
          <Link to={countryPath + '/integrationKey'}>Integration Key</Link>
        </TabItem>
      </Tabs>
      <TabsContent>
        <Switch>
          <Route
            exact
            path={countryPath}
            render={() => (
              <TabPanel isActive id={countryPath}>
                <CountryGeneral country={c} />
              </TabPanel>
            )}
          />
          <Route
            exact
            path={countryPath + '/name'}
            render={() => (
              <TabPanel isActive id={countryPath}>
                <Translations
                  translations={c.name}
                  key={'name'}
                  update={updateCountryName}
                />
              </TabPanel>
            )}
          />
          <Route
            exact
            path={countryPath + '/official'}
            render={() => (
              <TabPanel isActive>
                <Translations
                  translations={c.official}
                  key={'official'}
                  update={updateOfficialCountryName}
                />
              </TabPanel>
            )}
          />
          <Route
            exact
            path={countryPath + '/integrationKey'}
            render={() => (
              <TabPanel isActive>
                <IntegrationKey
                  integrationKey={c.integrationKey}
                  update={updateCountryIntgKey}
                />
              </TabPanel>
            )}
          />
          <Route
            exact
            path={countryPath + '/regions'}
            render={() => (
              <TabPanel isActive>
                <ListRegions {...{ countryCode, getRegionPath }} />
              </TabPanel>
            )}
          />
        </Switch>
      </TabsContent>
    </div>
  );
};

export default Country;
