import React, { useCallback, useMemo } from 'react';

import { FieldGrid } from '../Fields';

import { SOURCE_TYPES, DESTINATION_TYPES, CHANNEL_TYPES } from './constants';

const types = {
  source: SOURCE_TYPES,
  destination: DESTINATION_TYPES,
  channel: CHANNEL_TYPES,
};

export const EntityType = ({ purpose, entity, setEntityType }) => {
  const isEdit = !!entity.entityId;
  const { entityType } = entity;

  const onChange = useCallback(
    (event) => {
      if (!isEdit) {
        setEntityType(event.target.value);
      }
    },
    [setEntityType, isEdit]
  );

  const title = useMemo(
    () => purpose.charAt(0).toUpperCase() + purpose.slice(1) + ' Type',
    [purpose]
  );

  return (
    <FieldGrid name={title}>
      <select
        className="input-group-field"
        onChange={onChange}
        name="entityType"
        value={entityType}
        disabled={isEdit}
      >
        {types[purpose].map((s, i) => (
          <option key={i} value={s}>
            {s}
          </option>
        ))}
      </select>
    </FieldGrid>
  );
};

export default EntityType;
