import assert from 'assert';
import React, {
  useContext,
  useMemo,
  createContext,
  useState,
  useEffect,
  useCallback,
  useReducer,
} from 'react';

import { Link, Icon } from 'react-foundation';

const makeResponseHandler = (resolve, reject) => (response) => {
  if (response.success) {
    resolve(response);
  } else if (response.data) {
    resolve(response.data);
  } else if (response.error) {
    reject(response.error);
  } else if (response) {
    resolve(response);
  } else {
    reject('no response');
  }
};

const initFB = (appId) => {
  const initParams = {
    appId: appId,
    autoLogAppEvents: true,
    xfbml: true,
    status: true,
    version: 'v3.2',
  };

  if (window.FB) {
    return new Promise((resolve, reject) => {
      window.FB.init(initParams);
      resolve(window.FB);
    });
  }

  return new Promise((resolve, reject) => {
    window.fbAsyncInit = function () {
      window.FB.init(initParams);
      resolve(window.FB);
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
};

export const getLoggedIn = async (appId) => {
  assert(appId);
  const FB = await initFB(appId);
  let status = await new Promise((res, rej) => FB.getLoginStatus(res));
  if (status.status !== 'connected') {
    status = await new Promise((res, rej) =>
      FB.login(res, { scope: 'manage_pages,pages_show_list' })
    );
  }
  assert.equal('connected', status.status);
  return status;
};

export const getAppDetails = async (appId) => {
  const [app, pic] = await Promise.all([
    new Promise((res, rej) => window.FB.api(`/${appId}`, res)),
    new Promise((res, rej) =>
      window.FB.api(`/${appId}/picture`, { redirect: false }, res)
    ),
  ]);
  return { pic, app };
};

export const getUserPages = async () => {
  return await new Promise((res, rej) =>
    window.FB.api(`/me/accounts`, makeResponseHandler(res, rej))
  );
};

export const getUserInfo = async () => {
  return await new Promise((res, rej) =>
    window.FB.api(`/me`, makeResponseHandler(res, rej))
  );
};

export const getUserApps = async () => {
  return await new Promise((res, rej) =>
    window.FB.api(`/me/applications`, makeResponseHandler(res, rej))
  );
};

export const getSubscriptions = async (pageId, pageAccessToken) => {
  return await new Promise((res, rej) =>
    window.FB.api(
      `/${pageId}/subscribed_apps`,
      { access_token: pageAccessToken },
      makeResponseHandler(res, rej)
    )
  );
};

export const subscribeWebhooks = async (pageId, pageAccessToken) => {
  return await new Promise((res, rej) =>
    window.FB.api(
      `/${pageId}/subscribed_apps`,
      'POST',
      { subscribed_fields: ['feed'], access_token: pageAccessToken },
      makeResponseHandler(res, rej)
    )
  );
};

export const unsubscribeWebhooks = async (pageId, pageAccessToken) => {
  return await new Promise((res, rej) =>
    window.FB.api(
      `/${pageId}/subscribed_apps`,
      { access_token: pageAccessToken },
      'DELETE',
      makeResponseHandler(res, rej)
    )
  );
};

export const getPagePosts = async (pageId) => {
  return await new Promise((res, rej) =>
    window.FB.api(`/${pageId}/feed`, makeResponseHandler(res, rej))
  );
};

export const logout = async () => {
  return await new Promise((res, rej) =>
    window.FB.logout(makeResponseHandler(res, rej))
  );
};

export const FacebookContext = createContext();

export const useFacebookContext = () => useContext(FacebookContext);

export const LogOutButton = () => {
  const { isReady, logout, setAppId } = useFacebookContext();
  const [isDisabled, setIsDisabled] = useState(true);

  const onClick = useCallback(() => {
    if (logout) {
      setIsDisabled(true);
      logout().then(() => setAppId(false));
    }
  }, [logout, setAppId, setIsDisabled]);

  useEffect(() => {
    if (isReady) {
      setIsDisabled(false);
    }
  }, [isReady, setIsDisabled]);

  if (isReady) {
    return (
      <Link onClick={onClick} disabled={isDisabled}>
        <Icon name="fi-social-facebook" style={{ fontSize: '24px' }} /> Logout
      </Link>
    );
  } else {
    return '';
  }
};

const loggedInFunctions = {
  subscribeWebhooks,
  unsubscribeWebhooks,
  getSubscriptions,
  getUserPages,
  getAppDetails,
  getUserApps,
  getUserInfo,
  getPagePosts,
  logout,
};

const SET_STATUS = 'SET_STATUS';
const SET_APP_ID = 'SET_APP_ID';
const SET_HAS_INIT = 'SET_HAS_INIT';
const START_LOGIN = 'START_LOGIN';
const CLEAR_LOGIN_CB = 'CLEAR_LOGIN_CB';
const initialState = {
  appId: false,
  status: false,
  authState: false,
  hasInit: false,
};
const LOGGED_IN = 'LOGGED_IN';
const INITIALISED = 'INITIALISED';

const authReducer = (state, action) => {
  const { appId } = action;
  switch (action.type) {
    case SET_STATUS:
      const { status } = action;
      return {
        ...state,
        status,
        authState:
          status && status.status === 'connected' ? LOGGED_IN : INITIALISED,
      };
    case SET_APP_ID:
      return { ...initialState, appId };
    case SET_HAS_INIT:
      const { hasInit } = action;
      return {
        ...state,
        authState: hasInit ? INITIALISED : false,
        status: false,
      };
    case START_LOGIN:
      const { loginCb } = action;
      return { ...initialState, appId, loginCb };
    case CLEAR_LOGIN_CB:
      return { ...state, loginCb: null };
    default:
      throw new Error('Unrecognised action');
  }
};

const useAuthReducer = () => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setStatus = useCallback(
    (status) => dispatch({ type: SET_STATUS, status }),
    [dispatch]
  );
  const setAppId = useCallback(
    (appId) => dispatch({ type: SET_APP_ID, appId }),
    [dispatch]
  );
  const setHasInit = useCallback(
    (hasInit) => dispatch({ type: SET_HAS_INIT, hasInit }),
    [dispatch]
  );
  const startLogin = useCallback(
    (appId, loginCb) => dispatch({ type: START_LOGIN, appId, loginCb }),
    [dispatch]
  );
  const clearloginCb = useCallback(
    () => dispatch({ type: CLEAR_LOGIN_CB }),
    [dispatch]
  );

  const actions = useMemo(
    () => ({ setStatus, setAppId, setHasInit, startLogin, clearloginCb }),
    [setStatus, setAppId, setHasInit, startLogin, clearloginCb]
  );

  return [state, actions];
};

export const LoginButton = (props) => {
  const { hasInit, isReady } = useFacebookContext();

  if (hasInit) {
    return (
      <div
        className="fb-login-button"
        data-max-rows="1"
        data-size="large"
        data-button-type="continue_with"
        data-use-continue-as="true"
        data-scope="manage_pages,pages_show_list"
      />
    );
  } else if (isReady) {
    return <LogOutButton />;
  }

  return null;
};

export const FacebookContextProvider = ({ children, ...props }) => {
  const [
    { appId, authState, status, loginCb },
    { setStatus, setAppId, setHasInit, startLogin, clearloginCb },
  ] = useAuthReducer();

  useEffect(() => {
    if (props.appId) {
      setAppId(props.appId);
    }
  }, [setAppId, props.appId]);

  useEffect(() => {
    if (authState === INITIALISED && loginCb) {
      window.FB.login(
        (status) => {
          clearloginCb();
          loginCb(status);
        },
        { scope: 'manage_pages,pages_show_list' }
      );
    }
  }, [loginCb, authState, clearloginCb]);

  useEffect(() => {
    const cleanUp = () =>
      cleanUp.FB && cleanUp.FB.Event.unsubscribe('auth.statusChange');
    if (appId) {
      initFB(appId).then((FB) =>
        new Promise((res, rej) => FB.getLoginStatus(res))
          .then(setStatus)
          .then(FB.Event.subscribe('auth.statusChange', setStatus))
          .then(() => (cleanUp.FB = FB))
          .then(() => setHasInit(true))
      );
    }

    return cleanUp;
  }, [appId, setStatus, setHasInit]);

  const value = useMemo(
    () => ({
      appId,
      setAppId,
      isReady: authState === LOGGED_IN,
      isRequred: !!appId,
      hasInit: authState === INITIALISED,
      authState,
      status,
      ...(authState === LOGGED_IN ? loggedInFunctions : {}),
      startLogin,
    }),
    [appId, status, authState, startLogin, setAppId]
  );

  return (
    <FacebookContext.Provider value={value}>
      {children}
    </FacebookContext.Provider>
  );
};

export default useFacebookContext;
