import React, { useCallback, useState } from 'react';
import { Link, ButtonGroup, Grid, Cell, Colors } from 'react-foundation';
import { push, getLocation } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

import useBindingContext from '../useBindingContext';
import { LoginButton } from '../../../hooks/useFacebookContext';

export const ButtonsSection = ({ children }) => (
  <Grid>
    <Cell small={1}>
      <LoginButton />
    </Cell>
    <Cell small={3} offsetOnSmall={8}>
      {children}
    </Cell>
  </Grid>
);

export const Buttons = () => {
  const [state, { crud }] = useBindingContext();
  const dispatch = useDispatch();
  const location = useSelector(getLocation);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleReset = useCallback(() => crud.reset(), [crud]);
  const handleDelete = useCallback(() => crud.delete(), [crud]);
  const handleUpdate = useCallback(() => crud.update(state), [state, crud]);
  const handleCreate = useCallback(() => crud.create(state), [state, crud]);
  const handleCancel = useCallback(
    (event) => {
      event.preventDefault();
      const newPath = location.pathname.split('/').slice(0, -1).join('/');
      dispatch(push(newPath));
    },
    [dispatch, location]
  );

  const handleConfirm = useCallback(() => {
    setShowConfirm(true);
  }, [setShowConfirm]);

  const isCreate = !state.bindingId;

  return (
    <ButtonsSection>
      <ButtonGroup isExpanded>
        {showConfirm ? (
          <Link onClick={handleDelete} color={Colors.ALERT}>
            Confirm
          </Link>
        ) : (
          <Link onClick={handleConfirm} color={Colors.WARNING}>
            Delete
          </Link>
        )}
        <Link onClick={handleCancel}>Cancel</Link>
        <Link onClick={handleReset}>Reset</Link>
        {isCreate ? (
          <Link onClick={handleCreate}>Create</Link>
        ) : (
          <Link onClick={handleUpdate}>Update</Link>
        )}
      </ButtonGroup>
    </ButtonsSection>
  );
};

export default Buttons;
