/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://4ojb5oj4v5b3hfeqqxmsueh2qi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-af5udk5l7fgtjo2k33vcz7pfd4",
    "aws_cognito_identity_pool_id": "eu-west-1:2b57abd8-6b1f-4060-a5aa-e284b4327483",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_HJB1AbH1N",
    "aws_user_pools_web_client_id": "4qp2m0g35ld4ho5iim4imkkkuo",
    "oauth": {
        "domain": "bridgegraphql5835b8a4-5835b8a4-devfour.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.bridge.one.org/",
        "redirectSignOut": "http://localhost:3000/,https://dev.bridge.one.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
