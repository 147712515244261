import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';

import PaginatedSearch from '../PaginatedSearch';

const getCountryRegionsQuery = `query GetCountryRegions(
  $id: ID!, $nextToken: String, $limit: Int
) {
  getCountry(id: $id) {
    id
    regions(nextToken: $nextToken, limit: $limit) {
      items {
        id
        regionCode
        name {
          id
          name
        }
        integrationKey {
          id
          ActionKit
        }
      }
      nextToken
    }
  }
}
`;

const getCountryRegions = async (args) => {
  const { nextToken, limit = 75, countryCode } = args || {};
  const options = { nextToken, limit, id: countryCode };
  const { data } = await API.graphql(
    graphqlOperation(getCountryRegionsQuery, options)
  );
  if (data) {
    const { items = [], nextToken } = data.getCountry.regions;
    return [items, nextToken];
  }
};

export const ListRegions = (props = {}) => {
  const { countryCode, getRegionPath } = props;

  const ListRows = ({ items = [] }) =>
    items.map((i) => {
      const regionPath = getRegionPath(countryCode, i.regionCode);
      return (
        <tr key={i.id}>
          <td>
            <Link to={regionPath}>{i.regionCode}</Link>
          </td>
          <td>{JSON.parse(i.name.name).eng}</td>
          <td>{i.integrationKey.ActionKit}</td>
        </tr>
      );
    });

  const ListView = (props) => (
    <table>
      <thead>
        <tr>
          <th rowSpan={2}>Region Code</th>
          <th rowSpan={2}>Name (eng)</th>
          <th>Integraion Keys</th>
        </tr>
        <tr>
          <th>ActionKit</th>
        </tr>
      </thead>
      <tbody>
        <ListRows {...props} />
      </tbody>
    </table>
  );

  return (
    <PaginatedSearch
      getItems={(args) => getCountryRegions({ countryCode, ...(args || {}) })}
    >
      {({ items }) => <ListView items={items} />}
    </PaginatedSearch>
  );
};

export default ListRegions;
