import { API } from 'aws-amplify';
import { scrubEntity } from '@one/entity-helper';

import { amplifyErrorMessage } from '../redux/helpers/amplify';

export const fetchSources = async (entityType, channelRef) => {
  try {
    const [origin, entityName] = entityType.toLowerCase().split(':');
    const { payload } = await API.get(
      'admin',
      `/source/${origin}/${entityName}/find`,
      { queryStringParameters: { channelRef } }
    );
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export const fetchDestinations = async (entityType, channelRef) => {
  try {
    const [origin, entityName] = entityType.toLowerCase().split(':');
    const { payload } = await API.get(
      'admin',
      `/destination/${origin}/${entityName}/find`,
      { queryStringParameters: { channelRef } }
    );
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export const createSource = async (source) => {
  const fn = source.selectedFunction;
  // delete source.selectedFunction;
  const [origin, entityName] = source.entityType.toLowerCase().split(':');

  try {
    const { payload } = await API.post(
      'admin',
      `/source/${origin}/${entityName}/${fn}`,
      {
        body: scrubEntity(source),
      }
    );
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export const createDestination = async (dest) => {
  const fn = dest.selectedFunction;
  // delete source.selectedFunction;
  const [origin, entityName] = dest.entityType.toLowerCase().split(':');

  try {
    const { payload } = await API.post(
      'admin',
      `/destination/${origin}/${entityName}/${fn}`,
      {
        body: scrubEntity(dest),
      }
    );
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export const getPagePosts = async (channelRef, channelItemRef) => {
  try {
    const { payload } = await API.get(
      'admin',
      `/source/facebook/pagefeed/getPagePosts`,
      {
        queryStringParameters: {
          channelRef,
          channelItemRef,
        },
      }
    );
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export default {
  createSource,
  createDestination,
  fetchSources,
  fetchDestinations,
  getPagePosts,
};
