import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getHash } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Tabs, TabsContent, TabItem, TabPanel } from 'react-foundation';
import { CORE_ENDPOINT } from '@one/entity-helper/config';

import Action, { ActionType } from '../Action';
import Trigger, { TriggerType } from '../Trigger';
import Source, { SourceType } from '../Source';
import Destination, { DestinationType } from '../Destination';
import General from '../General';
import Buttons from './Buttons';
import Events from '../../Events';
import useBindingContext from '../useBindingContext';
import BindingInfo from '../Info';

export const FormTabs = () => {
  const [isActive, setIsActive] = useState('general');
  const hash = useSelector(getHash);
  const [binding] = useBindingContext();

  useEffect(() => {
    if (hash) {
      setIsActive(hash.substring(1));
    }
  }, [hash, setIsActive]);

  const { shortRef, broadcastEventsLive, source } = binding;

  const allowPublish = source.entityType === CORE_ENDPOINT;

  return (
    <div>
      <Tabs>
        <TabItem isActive={isActive === 'general'}>
          <Link to="#general">General</Link>
        </TabItem>
        <TabItem isActive={isActive === 'source'}>
          <Link to="#source">Source</Link>
        </TabItem>
        <TabItem isActive={isActive === 'trigger'}>
          <Link to="#trigger">Trigger</Link>
        </TabItem>
        <TabItem isActive={isActive === 'destination'}>
          <Link to="#destination">Destination</Link>
        </TabItem>
        <TabItem isActive={isActive === 'action'}>
          <Link to="#action">Action</Link>
        </TabItem>
        {broadcastEventsLive && (
          <TabItem isActive={isActive === 'events'}>
            <Link to="#events">Live Events</Link>
          </TabItem>
        )}
      </Tabs>
      <TabsContent>
        <TabPanel id="general" isActive={isActive === 'general'} height="100%">
          <General />
          <BindingInfo />
          <SourceType disabled={true} />
          <TriggerType disabled={true} />
          <DestinationType disabled={true} />
          <ActionType disabled={true} />
        </TabPanel>
        <TabPanel id="source" isActive={isActive === 'source'}>
          <SourceType />
          <Source />
        </TabPanel>
        <TabPanel id="trigger" isActive={isActive === 'trigger'}>
          <TriggerType />
          <Trigger />
        </TabPanel>
        <TabPanel id="destination" isActive={isActive === 'destination'}>
          <DestinationType />
          <Destination />
        </TabPanel>
        <TabPanel id="action" isActive={isActive === 'action'}>
          <ActionType />
          <Action />
        </TabPanel>
        {broadcastEventsLive && (
          <TabPanel id="events" isActive={isActive === 'events'}>
            <Events shortRef={shortRef} allowPublish={allowPublish} />
          </TabPanel>
        )}
      </TabsContent>
      <Buttons />
    </div>
  );
};

export default FormTabs;
