import React from 'react';

import Tabs from './Tabs';
import Wizard from './Wizard';
import useBindingContext from '../useBindingContext';

export default () => {
  const [state] = useBindingContext();
  const isEdit = !!state.bindingId;

  return <form>{isEdit ? <Tabs /> : <Wizard />}</form>;
};
