import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  TopBarLeft,
  TopBarRight,
  TopBarTitle,
  Menu,
  MenuItem,
  ResponsiveNavigation,
} from 'react-foundation';

import { signOut } from '../../redux/modules/auth';
import Logo from './Logo';
import { publicName } from '../../config';

export default () => {
  const dispatch = useDispatch();
  const handleSignOut = useCallback(() => dispatch(signOut()), [dispatch]);

  return (
    <ResponsiveNavigation className="horizontal">
      <TopBarTitle>
        <Link to="/">
          <Logo />
        </Link>
      </TopBarTitle>
      <TopBarLeft>
        <Menu>
          <MenuItem>
            <Link to="/">
              <h5>{publicName}</h5>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/bindings">
              <h5>Bindings</h5>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/channels">
              <h5>Channels</h5>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/graphs">
              <h5>Analytics</h5>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/countries">
              <h5>Countries</h5>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/documentation">
              <h5>Documentation</h5>
            </Link>
          </MenuItem>
        </Menu>
      </TopBarLeft>
      <TopBarRight>
        <Menu>
          <MenuItem>
            <Link to="/account">
              <h5>Account</h5>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="#" onClick={handleSignOut}>
              <h5>Sign Out</h5>
            </Link>
          </MenuItem>
        </Menu>
      </TopBarRight>
    </ResponsiveNavigation>
  );
};
