import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';

import PaginatedSearch from '../PaginatedSearch';
import Flag from './Flag';

const listCountriesQuery = `query ListCountries(
  $limit: Int
  $nextToken: String
) {
  countriesByPlanet(
    planet: Earth, 
    limit: $limit, 
    nextToken: $nextToken
  ) {
    items {
      id
      name {
        id
        name
      }
      official {
        id
        name
      }
      regionTerm
      region
      subregion
      integrationKey {
        id
        ActionKit
      }
      languages
    }
    nextToken
  }
}
`;

const getCountries = async (args) => {
  const { nextToken, limit = 75 } = args || {};
  const options = { nextToken, limit };
  const { data } = await API.graphql(
    graphqlOperation(listCountriesQuery, options)
  );
  if (data) {
    const { items = [], nextToken } = data.countriesByPlanet;
    return [items, nextToken];
  }
};

export const ListCountries = () => {
  const ListRows = ({ items = [] }) =>
    items.map((i) => {
      const countryPath = `/countries/${i.id}`;
      return (
        <tr key={i.id}>
          <td>
            <Flag countryCode={i.id} />
            &nbsp;
            <Link to={countryPath}>{i.id}</Link>
          </td>
          <td>{JSON.parse(i.name.name).eng}</td>
          <td>{JSON.parse(i.official.name).eng}</td>
          <td>{i.regionTerm}</td>
          <td>{i.integrationKey.ActionKit}</td>
        </tr>
      );
    });

  const ListView = (props) => (
    <table>
      <thead>
        <tr>
          <th rowSpan={2}>Country Code</th>
          <th rowSpan={2}>Name (eng)</th>
          <th rowSpan={2}>Official Name (eng)</th>
          <th rowSpan={2}>RegionTerm</th>
          <th>Integraion Keys</th>
        </tr>
        <tr>
          <th>ActionKit</th>
        </tr>
      </thead>
      <tbody>
        <ListRows {...props} />
      </tbody>
    </table>
  );

  return (
    <div>
      <h3>Countries</h3>
      <hr />
      <PaginatedSearch getItems={getCountries}>
        {({ items }) => <ListView items={items} />}
      </PaginatedSearch>
    </div>
  );
};

export default ListCountries;
