import React, { useState } from 'react';
import { Button, Cell, Grid } from 'react-foundation';

export const IntegrationKey = (props) => {
  const { integrationKey, update } = props;
  const [values, setValues] = useState(integrationKey);
  const [changed, setChanged] = useState(false);

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setChanged(true);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setChanged(false);
    if (update) {
      const result = await update(values);
      setValues(result);
    }
  };

  const Fields = () =>
    Object.keys(values)
      .sort()
      .map((k) => {
        if (k === 'id') {
          return null;
        }
        const key = `${values.id}-${k}`;
        return (
          <Grid className="input-group" key={key}>
            <Cell className="input-group-label" small={2}>
              {k}
            </Cell>
            <Cell small={10}>
              <input
                className="input-group-field"
                type="text"
                placeholder="Name"
                name={k}
                value={values[k] || ''}
                onChange={onChange}
              />
            </Cell>
          </Grid>
        );
      })
      .filter((f) => !!f);

  return (
    <form onSubmit={onSubmit}>
      <Fields />
      <Button type={'submit'} isDisabled={!changed}>
        Save Keys
      </Button>
    </form>
  );
};

export default IntegrationKey;
