import React from 'react';
import { Grid, Cell } from 'react-foundation';

export default () => (
  <Grid>
    <Cell small={5}>
      <hr />
    </Cell>
    <Cell small={2}>
      <center>
        <b>Or</b>
      </center>
    </Cell>
    <Cell small={5}>
      <hr />
    </Cell>
  </Grid>
);
