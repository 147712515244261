import { useReducer, useMemo } from 'react';

export const SET_STATE = 'SET_STATE';
export const SET_FIELD = 'SET_FIELD';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_VALUES = 'SET_VALUES';

const simpleReducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return { ...action.state };
    case SET_VALUES:
      return { ...action.values };
    case SET_FIELD:
      return {
        ...state,
        [action.name]: action.value,
      };
    case SET_FIELDS:
      return {
        ...state,
        ...action.fields,
      };
    default:
      throw new Error(`Unknown simple action ${action.type}`);
  }
};

export const makeSimpleApi = (dispatch) => {
  const setValues = (values) => dispatch({ type: SET_VALUES, values });
  const setField = (name, value) => dispatch({ type: SET_FIELD, name, value });
  const setFields = (fields) => dispatch({ type: SET_FIELDS, fields });
  const setState = (state) => dispatch({ type: SET_STATE, state });
  return { setValues, setField, setFields, setState };
};

export const useSimpleApi = (initialState) => {
  const [entity, dispatch] = useReducer(simpleReducer, initialState);
  const api = useMemo(() => makeSimpleApi(dispatch), [dispatch]);
  return [entity, api];
};

export default useSimpleApi;
