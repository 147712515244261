import { useReducer, useMemo } from 'react';

import {
  makeSimpleApi,
  SET_STATE,
  SET_FIELD,
  SET_FIELDS,
  SET_VALUES,
} from '../../hooks/useSimpleApi';

import { ENTITY_CONFIG, entityDefaults } from '@one/entity-helper';

const SET_ENTITY_TYPE = 'SET_ENTITY_TYPE';

const entityReducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return { ...action.state };
    case SET_VALUES:
      return {
        ...state,
        body: {
          ...action.values,
        },
      };
    case SET_ENTITY_TYPE:
      const defaults = entityDefaults(action.entityType);
      return {
        ...state,
        entityType: action.entityType,
        selectedFunction:
          ENTITY_CONFIG[action.entityType].supportedFunctions[0],
        body: {
          ...defaults,
        },
      };
    case SET_FIELD:
      return {
        ...state,
        body: {
          ...state.body,
          [action.name]: action.value,
        },
      };
    case SET_FIELDS:
      return {
        ...state,
        body: {
          ...state.body,
          ...action.fields,
        },
      };
    default:
      throw new Error(`Unknown entity action ${action.type}`);
  }
};

const virtualEntityReducer = (state, action) => {
  const { type, data } = state;
  switch (action.type) {
    case SET_STATE:
      return { ...action.state };
    case SET_VALUES:
      return { type, data: action.values };
    case SET_ENTITY_TYPE:
      const defaults = entityDefaults(action.entityType);
      return { type: action.entityType, data: { ...defaults } };
    case SET_FIELD:
      return {
        type,
        data: {
          ...data,
          [action.name]: action.value,
        },
      };
    case SET_FIELDS:
      return {
        type,
        data: {
          ...data,
          ...action.fields,
        },
      };
    default:
      throw new Error(`Unknown virtual entity action ${action.type}`);
  }
};

const makeEntityApi = (dispatch) => {
  const api = makeSimpleApi(dispatch);
  api.setEntityType = (entityType) =>
    dispatch({ type: SET_ENTITY_TYPE, entityType });
  return api;
};

export const useEntityApi = (initialState) => {
  const [entity, dispatch] = useReducer(entityReducer, initialState);
  const api = useMemo(() => makeEntityApi(dispatch), [dispatch]);
  return [entity, api];
};

export const useVirtualEntityApi = (initialState) => {
  const [entity, dispatch] = useReducer(virtualEntityReducer, initialState);
  const api = useMemo(() => makeEntityApi(dispatch), [dispatch]);
  return [entity, api];
};

export default useEntityApi;
