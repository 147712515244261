import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { isSupported } from '@one/entity-helper/lib';

import Description from '../Entity/Description';
import FindExistingEntity from '../Entity/FindExisting';
import Fields from '../Entity/Fields';
import { FieldGrid } from '../Fields';
import FindEntity from '../Entity/FindEntity';
import EntityType from '../Entity/Type';
import useBindingContext from './useBindingContext';
import { SelectChannel, useChannelContext } from '../Entity/useChannelContext';

import { FACEBOOK_PAGE_FEED } from '../Entity/constants';

export const DestinationType = (props) => {
  const [state, api] = useBindingContext();
  const { destination } = state;
  const { setEntityType } = api.destination;

  return (
    <EntityType
      entity={destination}
      purpose="destination"
      setEntityType={setEntityType}
    />
  );
};

export const DestinationContainer = (props) => {
  const [state, api] = useBindingContext();
  const { usesChannel } = useChannelContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const { source, destination } = state;
  const { setValues } = api.destination;

  const isCreateBinding = !state.bindingId;
  const { entityType } = destination;
  const { channelRef } = destination.body;

  const handleSelection = useCallback(
    (selection = {}) => {
      setValues(selection);
    },
    [setValues]
  );

  useEffect(() => {
    if (isCreateBinding && entityType === FACEBOOK_PAGE_FEED) {
      setIsDisabled(true);
      if (source.entityType === FACEBOOK_PAGE_FEED) {
        api.destination.setState(source);
      }
    }
  }, [source, isCreateBinding, entityType, setIsDisabled, api.destination]);

  const showImport = useMemo(() => {
    if (!isCreateBinding) {
      return false;
    }

    if (entityType === FACEBOOK_PAGE_FEED) {
      return false;
    }

    return isSupported(entityType, 'import');
  }, [entityType, isCreateBinding]);

  const requiresChannel = useMemo(
    () => usesChannel(entityType),
    [entityType, usesChannel]
  );
  const dfns = useMemo(() => {
    return requiresChannel
      ? {
          channelRef: { suppressed: true },
          channelName: { suppressed: true },
        }
      : {};
  }, [requiresChannel]);

  // TODO: FindExisting needs to handle channels too.
  return (
    <div>
      {requiresChannel && (
        <SelectChannel
          entity={destination}
          entityType={entityType}
          setFields={api.destination.setFields}
        />
      )}
      <Description entityType={entityType} />
      {isCreateBinding && (
        <FindExistingEntity
          state={destination}
          setState={api.destination.setState}
          initialState={api.initialState.destination}
          purpose="destination"
          disabled={requiresChannel && !channelRef}
        />
      )}
      {showImport && (
        <div>
          <FieldGrid name="Import">
            <FindEntity
              entityType={entityType}
              channelRef={channelRef}
              purpose="destination"
              handleSelection={handleSelection}
              disabled={requiresChannel && !channelRef}
            />
          </FieldGrid>
        </div>
      )}
      <Fields
        entity={destination}
        api={api.destination}
        disabled={isDisabled ? true : undefined}
        dfns={dfns}
      />
    </div>
  );
};

export default DestinationContainer;
