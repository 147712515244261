import React, { useCallback, useMemo } from 'react';

import { Cell, Grid } from 'react-foundation';
import AceEditor from 'react-ace';
import 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import 'brace/theme/monokai';
import Handlebars from 'handlebars/dist/cjs/handlebars';

Handlebars.registerHelper('json', function (context) {
  return JSON.stringify(context, null, 2);
});

export const TemplateContainer = ({
  template = '',
  onChange,
  name,
  fieldSet,
}) => {
  const dummyValues = useMemo(
    () => ({
      user: {
        name: 'Bridge User',
        ref: 'bridgeUser666',
      },
      'user.ref': 'bridgeUser666',
      'user.name': 'Bridge User',
      'user.type': 'Core:User',
      ...(fieldSet || {}),
    }),
    [fieldSet]
  );

  const setTemplate = useCallback(
    (value) => onChange({ target: { name, value } }),
    [name, onChange]
  );

  const compileTemplate = useCallback(
    (template) => {
      try {
        return Handlebars.compile(template)(dummyValues);
      } catch (error) {
        console.error(error);
        return template;
      }
    },
    [dummyValues]
  );

  const compiled = useMemo(
    () => compileTemplate(template),
    [template, compileTemplate]
  );

  const options = {
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: false,
    enableSnippets: false,
    showLineNumbers: false,
    tabSize: 2,
    $blockScrolling: Infinity,
  };

  return (
    <Grid>
      <Cell small={12} medium={6}>
        <AceEditor
          mode="json"
          theme="github"
          name="editable"
          onLoad={null}
          onChange={setTemplate}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={template}
          height="10em"
          editorProps={options}
        />
      </Cell>
      <Cell small={12} medium={6}>
        <AceEditor
          mode="json"
          theme="monokai"
          name="example"
          onLoad={null}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={compiled}
          readOnly={true}
          height="10em"
          editorProps={options}
        />
      </Cell>
    </Grid>
  );
};

export default TemplateContainer;
