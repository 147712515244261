import React, { useMemo } from 'react';

import Description from '../Entity/Description';
import FindExistingEntity from '../Entity/FindExisting';
import Fields from '../Entity/Fields';
import EntityType from '../Entity/Type';
import useBindingContext from './useBindingContext';
import { usesExisting } from '@one/entity-helper/lib';
import { SelectChannel, useChannelContext } from '../Entity/useChannelContext';

export const SourceType = (props) => {
  const [state, api] = useBindingContext();
  const { source } = state;
  const { setEntityType } = api.source;

  return (
    <EntityType
      entity={source}
      purpose="source"
      setEntityType={setEntityType}
    />
  );
};

export const SourceContainer = (props) => {
  const [state, api] = useBindingContext();
  const { usesChannel } = useChannelContext();
  const { source } = state;
  const isCreateBinding = !state.bindingId;
  const { entityType } = source;
  const { channelRef } = source.body;

  const requiresChannel = useMemo(
    () => usesChannel(entityType),
    [entityType, usesChannel]
  );
  const dfns = useMemo(() => {
    return requiresChannel
      ? {
          channelRef: { suppressed: true },
          channelName: { suppressed: true },
        }
      : {};
  }, [requiresChannel]);

  const findExisting = useMemo(
    () => usesExisting(entityType) && isCreateBinding,
    [entityType, isCreateBinding]
  );

  return (
    <div>
      {requiresChannel && (
        <SelectChannel
          entity={source}
          entityType={entityType}
          setFields={api.source.setFields}
        />
      )}
      <Description entityType={entityType} />
      {findExisting && (
        <FindExistingEntity
          state={source}
          channelRef={channelRef}
          setState={api.source.setState}
          initialState={api.initialState.source}
          purpose="source"
          disabled={requiresChannel && !channelRef}
        />
      )}

      <Fields entity={source} api={api.source} dfns={dfns} />
    </div>
  );
};

export default SourceContainer;
