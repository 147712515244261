import { API, Auth } from 'aws-amplify';

import { createAction, createReducer } from '../helpers/redux';
import { createAmplifyErrorAction } from '../helpers/amplify';

import { SIGNED_IN, SIGNED_OUT } from '../constants';

const TEST_IAM = 'messaging/auth/TEST_IAM';
const TEST_IAM_SUCCESS = 'messaging/auth/TEST_IAM_SUCCESS';
const TEST_IAM_FAILURE = 'messaging/auth/TEST_IAM_FAILURE';

// State
const initialState = {
  isAuthenticated: false,
  userInfo: null,
};

// Actions
export const testIam_success = createAction(TEST_IAM_SUCCESS);
export const testIam_failure = createAmplifyErrorAction(TEST_IAM_FAILURE);
export const testIam = () => async (dispatch) => {
  dispatch({ type: TEST_IAM });
  try {
    const response = await API.get('admin', '/testiam');
    dispatch(testIam_success(response));
  } catch (error) {
    dispatch(testIam_failure(error));
  }
};

export const signedOut = createAction(SIGNED_OUT);
export const signOut = () => async (dispatch) => {
  await Auth.signOut();
  dispatch(signedOut());
};

export const signedIn = createAction(SIGNED_IN, 'userInfo');
export const updateAuthState = (authState) => async (dispatch, getState) => {
  switch (authState) {
    case 'signedOut':
      if (isAuthenticated(getState())) {
        dispatch(signOut());
      }
      break;

    case 'signedIn':
    case 'cognitoHostedUI':
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        dispatch(signedIn(userInfo));
      } catch (error) {
        console.error('CREDENTIAL ERROR', error);
        dispatch(signOut());
      }
      break;

    case 'signIn':
      // signIn dialogue displayed
      break;

    default:
      console.error(`unhandled authState: '${authState}'`);
  }
};

// Reducers
export default createReducer(initialState, {
  [SIGNED_IN]: (state, { userInfo }) => ({
    ...state,
    isAuthenticated: true,
    userInfo,
  }),
  [SIGNED_OUT]: (state) => initialState,
});

// Selectors
export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const getUserInfo = (state) => state.auth.userInfo;
