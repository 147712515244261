import React, { useCallback, useMemo, useEffect, useState } from 'react';

import useBindingContext from './useBindingContext';
import useErrorContext from '../../hooks/useErrorContext';
import useChannelContext from '../Entity/useChannelContext';

import { Field, FieldGrid } from '../Fields';
import Fields from '../Entity/Fields';
import Description from '../Entity/Description';
import { getPagePosts } from '../../rest/entities';

import { FACEBOOK_PAGE_FEED_COMMENT, ENTITY_CONFIG } from '../Entity/constants';

const truncate = (str, len = 60) => {
  if (str) {
    const truncLen = len - 3;
    if (str.length <= len) {
      return str;
    }
    return str.substring(0, truncLen) + '...';
  } else {
    return '';
  }
};

const PostOptions = (props) => {
  const [binding] = useBindingContext();
  const { source } = binding;
  const { setError } = useErrorContext();
  const [posts, setPosts] = useState([]);

  const { channelItemRef, channelRef } = source.body;

  useEffect(() => {
    if (channelRef && channelItemRef) {
      getPagePosts(channelRef, channelItemRef).then(setPosts).catch(setError);
    }
  }, [channelItemRef, setPosts, setError, channelRef]);

  return [
    <option key={0} value="">
      No Post Selected
    </option>,
    ...posts.map((p, i) => (
      <option key={i + 1} value={p.id}>
        [{p.created_time.split('+')[0]}] "{truncate(p.message || p.story)}"
      </option>
    )),
  ];
};

export const TriggerFields = (props) => {
  const [binding, api] = useBindingContext();
  const { getChannelByRef } = useChannelContext();

  const { triggerType, triggerRef, trigger = {}, source } = binding;
  const { setFields } = api.trigger;

  const onPostChange = useCallback(
    (event) => {
      const index = event.nativeEvent.target.selectedIndex;
      const pageTitle = event.nativeEvent.target[index].text;
      const { value } = event.target;
      setFields({
        postId: value,
        postTitle: pageTitle,
      });
    },
    [setFields]
  );

  const dfnOverrides = useMemo(() => {
    if (triggerType === FACEBOOK_PAGE_FEED_COMMENT) {
      const channel = getChannelByRef(source.body.channelRef);
      if (channel && channel.body.useManagePages) {
        return {
          postId: {
            options: <PostOptions />,
            onChange: onPostChange,
          },
        };
      } else {
        return {
          postId: {
            type: 'text',
          },
          postTitle: {
            disabled: false,
          },
        };
      }
    } else {
      return undefined;
    }
  }, [triggerType, onPostChange, source, getChannelByRef]);

  return (
    <div>
      <Fields
        entity={trigger}
        entityType={triggerType}
        setFields={setFields}
        dfns={dfnOverrides}
      />
      <Field name="triggerRef" value={triggerRef} type="text" disabled={true} />
    </div>
  );
};

export const TriggerContainer = (props) => {
  const [state] = useBindingContext();
  const { triggerType } = state;

  return (
    <div>
      <Description entityType={triggerType} />
      <TriggerFields />
    </div>
  );
};

export const TriggerType = (props) => {
  const [state, api] = useBindingContext();
  const { entityType } = state.source;

  const triggerTypeNames = useMemo(
    () => ENTITY_CONFIG[entityType].triggerTypes || [],
    [entityType]
  );

  useEffect(() => {
    if (!state.triggerType || !state.triggerType.startsWith(entityType)) {
      const newTriggerType = triggerTypeNames[0];
      if (newTriggerType !== state.triggerType) {
        api.trigger.setEntityType(newTriggerType);
      }
    }
  }, [api.trigger, entityType, state.triggerType, triggerTypeNames]);

  const onChange = useCallback(
    (event) => {
      api.trigger.setEntityType(event.target.value);
    },
    [api.trigger]
  );

  return (
    <FieldGrid name="triggerType">
      <select
        className="input-group-field"
        onChange={onChange}
        name="triggerType"
        value={state.triggerType}
      >
        {triggerTypeNames.map((s, i) => (
          <option key={i} value={s}>
            {s}
          </option>
        ))}
      </select>
    </FieldGrid>
  );
};

export default TriggerContainer;
