import { publicName } from '../../config';

export const createAction =
  (type, ...argNames) =>
  (...args) =>
    argNames.reduce((a, _, i) => ({ ...a, [argNames[i]]: args[i] }), { type });

export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) =>
    handlers.hasOwnProperty(action.type)
      ? handlers[action.type](state, action)
      : state;

export const constantNamer = (moduleName) => (constant) =>
  `${publicName}/${moduleName}/${constant}`;
