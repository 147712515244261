import React, { useState, useEffect, useCallback } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Switch, Route, Link } from 'react-router-dom';
import {
  Cell,
  Grid,
  Tabs,
  TabItem,
  TabsContent,
  TabPanel,
} from 'react-foundation';

import { getRegion as getRegionQuery } from '../../graphql/queries';
import {
  updateRegionName as updateRegionNameMutation,
  updateRegionIntgKey as updateRegionIntgKeyMutation,
} from '../../graphql/mutations';

import { FlagCell } from './Flag';
import IntegrationKey from './IntegrationKey';
import Translations from './Translations';

export const Region = (props) => {
  const { countryCode, regionCode, getRegionPath } = props;

  const [region, setRegion] = useState({});

  const id = `${countryCode}-${regionCode}`;
  const getRegion = useCallback(async () => {
    const { data } = await API.graphql(
      graphqlOperation(getRegionQuery, { id })
    );
    const { getRegion: newRegion = {} } = data;
    setRegion(newRegion);
  }, [id]);

  const updateRegionName = useCallback(
    async (input) => {
      const { data } = await API.graphql(
        graphqlOperation(updateRegionNameMutation, { input })
      );
      const { updateRegionName: newRegionName = {} } = data;
      setRegion({ ...region, name: newRegionName });
      return newRegionName;
    },
    [region]
  );

  const updateRegionIntgKey = useCallback(
    async (input) => {
      const { data } = await API.graphql(
        graphqlOperation(updateRegionIntgKeyMutation, { input })
      );
      const { updateRegionIntgKey: intgKey = {} } = data;
      const { country, ...newRegionIntgKey } = intgKey;
      setRegion({ ...region, integrationKey: newRegionIntgKey });
      return newRegionIntgKey;
    },
    [region]
  );

  useEffect(() => {
    getRegion();
  }, [getRegion]);

  const regionPath = getRegionPath(countryCode, regionCode);
  const engName = region.name ? JSON.parse(region.name.name).eng : '';

  const r = region;
  console.log(r);
  return (
    <div>
      <Grid>
        <Cell small={2}>
          <Grid vertical gridFrame="all">
            <FlagCell {...{ countryCode }} />
          </Grid>
        </Cell>
        <Cell small={10}>
          <Grid className="input-group">
            <Cell small={2} className="input-group-label">
              Region Code
            </Cell>
            <Cell small={10}>
              <input
                className="input-group-field"
                type="text"
                placeholder="regionCode"
                value={regionCode}
                readOnly
              />
            </Cell>
          </Grid>
          <Grid className="input-group">
            <Cell small={2} className="input-group-label">
              Name
            </Cell>
            <Cell small={10}>
              <input
                className="input-group-field"
                type="text"
                placeholder="Name"
                value={engName}
                readOnly
              />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
      <Tabs>
        <TabItem>
          <Link to={regionPath}>Name</Link>
        </TabItem>
        <TabItem>
          <Link to={regionPath + '/integrationKey'}>Integration Key</Link>
        </TabItem>
      </Tabs>
      <TabsContent>
        <Switch>
          <Route
            exact
            path={regionPath}
            render={() => (
              <TabPanel isActive>
                <Translations
                  translations={r.name}
                  key={'name'}
                  update={updateRegionName}
                />
              </TabPanel>
            )}
          />
          <Route
            exact
            path={regionPath + '/integrationKey'}
            render={() => (
              <TabPanel isActive>
                <IntegrationKey
                  integrationKey={r.integrationKey}
                  update={updateRegionIntgKey}
                />
              </TabPanel>
            )}
          />
        </Switch>
      </TabsContent>
    </div>
  );
};

export default Region;
