import React from 'react';

export const LogoContainer = () => (
  <svg id="icon-one" viewBox="0 0 100 100" height="50" width="50">
    <title>ONE</title>
    <path
      className="fill--black"
      d="M50.51,2A48.51,48.51,0,1,0,99,50.51,48.52,48.52,0,0,0,50.51,2h0ZM36.93,57.3c0,5.82-5.05,9.89-11.44,9.89C18.89,67.2,14,63.12,14,57.3V41.2c0-5.82,5.05-9.89,11.44-9.89s11.44,3.88,11.44,9.89V57.3Zm26.78,8.93H56.15L50.72,55l-2.33-4.86v16.1H40.24V32.07h8l5.23,11.44,2.14,4.86V31.89h8.15V66.23h0ZM83.3,45.47v6.41H76.5v6.41H87v8H67.2V32.07H86.79v7.37H76.32v6h7Zm-58-6.8a1.86,1.86,0,0,0-2.14,1.74V57.69a1.86,1.86,0,0,0,2.14,1.74,1.86,1.86,0,0,0,2.14-1.74V40.42A2,2,0,0,0,25.29,38.67Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default LogoContainer;
