/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      id
      languageCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      id
      languageCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      id
      languageCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      name {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      official {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      regions {
        items {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        nextToken
      }
      regionTerm
      planet
      region
      subregion
      integrationKey {
        id
        ActionKit
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      languages
      createdAt
      updatedAt
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      name {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      official {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      regions {
        items {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        nextToken
      }
      regionTerm
      planet
      region
      subregion
      integrationKey {
        id
        ActionKit
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      languages
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      name {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      official {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      regions {
        items {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        nextToken
      }
      regionTerm
      planet
      region
      subregion
      integrationKey {
        id
        ActionKit
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      languages
      createdAt
      updatedAt
    }
  }
`;
export const createCountryIntgKey = /* GraphQL */ `
  mutation CreateCountryIntgKey(
    $input: CreateCountryIntgKeyInput!
    $condition: ModelCountryIntgKeyConditionInput
  ) {
    createCountryIntgKey(input: $input, condition: $condition) {
      id
      ActionKit
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCountryIntgKey = /* GraphQL */ `
  mutation UpdateCountryIntgKey(
    $input: UpdateCountryIntgKeyInput!
    $condition: ModelCountryIntgKeyConditionInput
  ) {
    updateCountryIntgKey(input: $input, condition: $condition) {
      id
      ActionKit
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountryIntgKey = /* GraphQL */ `
  mutation DeleteCountryIntgKey(
    $input: DeleteCountryIntgKeyInput!
    $condition: ModelCountryIntgKeyConditionInput
  ) {
    deleteCountryIntgKey(input: $input, condition: $condition) {
      id
      ActionKit
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCountryName = /* GraphQL */ `
  mutation CreateCountryName(
    $input: CreateCountryNameInput!
    $condition: ModelCountryNameConditionInput
  ) {
    createCountryName(input: $input, condition: $condition) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCountryName = /* GraphQL */ `
  mutation UpdateCountryName(
    $input: UpdateCountryNameInput!
    $condition: ModelCountryNameConditionInput
  ) {
    updateCountryName(input: $input, condition: $condition) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountryName = /* GraphQL */ `
  mutation DeleteCountryName(
    $input: DeleteCountryNameInput!
    $condition: ModelCountryNameConditionInput
  ) {
    deleteCountryName(input: $input, condition: $condition) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const createOfficialCountryName = /* GraphQL */ `
  mutation CreateOfficialCountryName(
    $input: CreateOfficialCountryNameInput!
    $condition: ModelOfficialCountryNameConditionInput
  ) {
    createOfficialCountryName(input: $input, condition: $condition) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateOfficialCountryName = /* GraphQL */ `
  mutation UpdateOfficialCountryName(
    $input: UpdateOfficialCountryNameInput!
    $condition: ModelOfficialCountryNameConditionInput
  ) {
    updateOfficialCountryName(input: $input, condition: $condition) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteOfficialCountryName = /* GraphQL */ `
  mutation DeleteOfficialCountryName(
    $input: DeleteOfficialCountryNameInput!
    $condition: ModelOfficialCountryNameConditionInput
  ) {
    deleteOfficialCountryName(input: $input, condition: $condition) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion(
    $input: CreateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    createRegion(input: $input, condition: $condition) {
      id
      regionCode
      countryCode
      name {
        id
        countryCode
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      integrationKey {
        id
        ActionKit
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion(
    $input: UpdateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    updateRegion(input: $input, condition: $condition) {
      id
      regionCode
      countryCode
      name {
        id
        countryCode
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      integrationKey {
        id
        ActionKit
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion(
    $input: DeleteRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    deleteRegion(input: $input, condition: $condition) {
      id
      regionCode
      countryCode
      name {
        id
        countryCode
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      integrationKey {
        id
        ActionKit
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRegionIntgKey = /* GraphQL */ `
  mutation CreateRegionIntgKey(
    $input: CreateRegionIntgKeyInput!
    $condition: ModelRegionIntgKeyConditionInput
  ) {
    createRegionIntgKey(input: $input, condition: $condition) {
      id
      ActionKit
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRegionIntgKey = /* GraphQL */ `
  mutation UpdateRegionIntgKey(
    $input: UpdateRegionIntgKeyInput!
    $condition: ModelRegionIntgKeyConditionInput
  ) {
    updateRegionIntgKey(input: $input, condition: $condition) {
      id
      ActionKit
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegionIntgKey = /* GraphQL */ `
  mutation DeleteRegionIntgKey(
    $input: DeleteRegionIntgKeyInput!
    $condition: ModelRegionIntgKeyConditionInput
  ) {
    deleteRegionIntgKey(input: $input, condition: $condition) {
      id
      ActionKit
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRegionName = /* GraphQL */ `
  mutation CreateRegionName(
    $input: CreateRegionNameInput!
    $condition: ModelRegionNameConditionInput
  ) {
    createRegionName(input: $input, condition: $condition) {
      id
      countryCode
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateRegionName = /* GraphQL */ `
  mutation UpdateRegionName(
    $input: UpdateRegionNameInput!
    $condition: ModelRegionNameConditionInput
  ) {
    updateRegionName(input: $input, condition: $condition) {
      id
      countryCode
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegionName = /* GraphQL */ `
  mutation DeleteRegionName(
    $input: DeleteRegionNameInput!
    $condition: ModelRegionNameConditionInput
  ) {
    deleteRegionName(input: $input, condition: $condition) {
      id
      countryCode
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
