import { createReducer, constantNamer } from '../helpers/redux';
import { send, subscribe, PREPARED } from './pubsub';

import { SIGNED_IN, SIGNED_OUT, MESSAGE_RECEIVED } from '../constants';

import { CORE } from '@one/entity-helper/config/core';
import { MESSAGE_PURPOSES } from '../../constants/messages';
import { stage } from '../../config';

const namer = constantNamer('events');

// Purposes
const { INGEST } = MESSAGE_PURPOSES;

// Topics
const SUBSCRIPTION_TOPIC = `messaging/${stage}/#`;
const PUBLISH_TOPIC = `messaging/${stage}/${INGEST}`;
// const ERRORS_TOPIC = `messaging/${stage}/${ERRORS}`;

const RESET = namer('RESET');

// State
const initialState = { messages: [] };

// Actions
export const sendMessage = send;
export const subscribeEvents = () => subscribe(SUBSCRIPTION_TOPIC);

export const publishEvent =
  (shortRef, name, userId, message) => async (dispatch) => {
    message = message || `publishEvent! ${new Date()}`;
    dispatch(
      send(`${PUBLISH_TOPIC}/${shortRef}/${CORE}`, {
        message,
        user: {
          ref: userId,
          name: name,
        },
        shortRef,
      })
    );
  };

// Listeners
export const listeners = {
  [PREPARED]: subscribeEvents,
};

// Reducers
const reduceMessageReceived = (state, { topic = '', value }) => {
  const [purpose = 'unknown', ref] = topic.split('/').slice(2);
  return {
    ...state,
    messages: [{ purpose, ref, topic, value }, ...state.messages.slice(0, 100)],
  };
};

export default createReducer(initialState, {
  [RESET]: () => initialState,
  [MESSAGE_RECEIVED]: reduceMessageReceived,

  // External
  [SIGNED_OUT]: () => initialState,
  [SIGNED_IN]: () => initialState,
});

// Selectors
export const getMessages = (state, shortRef) => {
  if (shortRef) {
    return state.events.messages.filter((ev) => ev.ref === shortRef);
  } else {
    return state.events.messages;
  }
};
