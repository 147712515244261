import React, { useCallback, useState } from 'react';
import {
  Switch,
  Cell,
  Grid,
  Icon,
  Badge,
  Link,
  ButtonGroup,
  Callout,
} from 'react-foundation';

import Template from './Template';
import KeyValue from './KeyValue';

const camel2title = (string) =>
  string
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, (firstLetter) => firstLetter.toUpperCase());

const RevealSet = ({ children, isRequired }) => {
  const [isVisible, showField] = useState(false);

  const onClick = useCallback(() => {
    showField(true);
  }, [showField]);

  if (isVisible) {
    return children;
  } else {
    return (
      <Grid>
        <Cell
          small={10}
          className="input-group-label"
          style={{ padding: '0.5rem' }}
        >
          {isRequired ? (
            <Badge>
              <Icon name="fi-alert" />
            </Badge>
          ) : (
            <Badge>
              <Icon name="fi-check" />
            </Badge>
          )}
          &nbsp;
        </Cell>
        <Cell small={2}>
          <ButtonGroup isExpanded={true} style={{ margin: 0 }}>
            <Link onClick={onClick} style={{ border: '2px solid transparent' }}>
              Set Value
            </Link>
          </ButtonGroup>
        </Cell>
      </Grid>
    );
  }
};

export const FieldTitle = ({ name, icon, isRequired, isInvalid }) => (
  <div>
    {icon}
    {camel2title(name)}&nbsp;
    {isRequired ? (
      <Icon name="fi-asterisk" />
    ) : isInvalid ? (
      <Badge>
        <Icon name="fi-alert" />
      </Badge>
    ) : null}
  </div>
);

export const FieldGrid = ({ children, revealSet, ...rest }) => (
  <Grid className="input-group">
    <Cell small={3} className="input-group-label">
      <FieldTitle {...rest} />
    </Cell>
    <Cell small={9}>
      {revealSet ? <RevealSet>{children}</RevealSet> : children}
    </Cell>
  </Grid>
);

export const FieldSet = ({ children, ...rest }) => (
  <fieldset className="fieldset">
    <legend>
      <h5>
        <FieldTitle {...rest} />
      </h5>
    </legend>
    {children}
  </fieldset>
);

export const Field = (props) => {
  const dfn = props.dfn || {};
  let {
    value = '',
    disabled = dfn.disabled,
    name = dfn.name,
    displayName = dfn.displayName,
    icon = dfn.icon,
    type = dfn.type,
    options = dfn.options,
    isRequired = dfn.isRequired,
    isInvalid,
    revealSet = dfn.revealSet,
    coearceValue = dfn.coearceValue,
    isServerSide = dfn.isServerSide,
    fieldSet = dfn.fieldSet,
    suppressed = dfn.suppressed,
  } = props;

  if (typeof disabled === 'function') {
    disabled = disabled();
  }

  const dfnOnChange = dfn.onChange;
  const propsOnChange = props.onChange;

  const onChange = useCallback(
    (event) => {
      if (disabled) {
        // do nothing
      } else if (dfnOnChange) {
        dfnOnChange(event);
      } else if (type === 'switch') {
        const { name, checked } = event.target;
        const target = { name, value: checked };
        propsOnChange({ target });
      } else if (propsOnChange) {
        propsOnChange(event);
      }
    },
    [disabled, dfnOnChange, propsOnChange, type]
  );

  if (suppressed) {
    return null;
  }

  const isSet = !!value;
  if (isServerSide && (revealSet || coearceValue)) {
    if (typeof value === 'boolean') {
      value = '';
    }
    if (typeof value === 'string' && !isSet) {
      revealSet = false;
      disabled = false;
    }
  }

  if (!displayName) {
    displayName = name;
  }

  const fieldGridProps = {
    name: displayName,
    icon,
    isRequired: isRequired && !isSet,
    isInvalid,
    revealSet,
  };

  let inner, outer, isFieldSet;
  switch (type) {
    case 'url':
      inner = (
        <input
          className="input-group-field"
          type="text"
          placeholder="http(s)://my-server.com/resource"
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
        />
      );
      break;
    case 'template':
      isFieldSet = true;
      inner = <Template {...{ onChange, template: value, name, fieldSet }} />;
      break;
    case 'keyvalue':
      isFieldSet = true;
      inner = <KeyValue {...{ onChange, value, name }} />;
      break;
    case 'text':
      inner = (
        <input
          className="input-group-field"
          type="text"
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
        />
      );
      break;
    case 'switch':
      inner = (
        <Switch
          input={{
            checked: !!value,
            onChange: onChange,
            disabled: disabled,
            name,
          }}
          style={{ margin: '0.25em', marginLeft: '0.5em' }}
          active={{ text: 'On' }}
          inactive={{ text: 'Off' }}
        />
      );
      break;
    case 'select':
      if (!options || disabled) {
        inner = (
          <input
            className="input-group-field"
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled || !isRequired}
          />
        );
      } else {
        inner = (
          <select
            className="input-group-field"
            onChange={onChange}
            name={name}
            value={value}
            disabled={disabled}
          >
            {options}
          </select>
        );
      }
      break;
    case 'alert':
      inner = (
        <div className="input-group-label">
          <Badge>
            {isSet ? <Icon name="fi-check" /> : <Icon name="fi-alert" />}
          </Badge>
          &nbsp;
        </div>
      );
      break;
    case 'snippet':
      if (value) {
        outer = <Callout dangerouslySetInnerHTML={{ __html: value }} />;
      } else {
        return null;
      }
      break;
    default:
      inner = <pre>{value}</pre>;
  }

  return outer ? (
    outer
  ) : isFieldSet ? (
    <FieldSet {...fieldGridProps}>{inner}</FieldSet>
  ) : (
    <FieldGrid {...fieldGridProps}>{inner}</FieldGrid>
  );
};

export default Field;
