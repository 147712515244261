import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ButtonGroup, Link, Grid, Cell } from 'react-foundation';

import { getMessages, publishEvent } from '../redux/modules/events';
import { getUserInfo } from '../redux/modules/auth';
import { Field } from './Fields';

import { MESSAGE_PURPOSES } from '../constants/messages';

const { INGEST, ERRORS, EVENTS } = MESSAGE_PURPOSES;

export const PublishEvent = (props) => {
  const { shortRef } = props;
  const [message, setMessage] = useState('PublishEvent!!');
  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();

  const handlePublishEvent = useCallback(() => {
    dispatch(publishEvent(shortRef, userInfo.name, userInfo.id, message));
  }, [dispatch, shortRef, userInfo, message]);

  const onChange = useCallback(
    (event) => setMessage(event.target.value),
    [setMessage]
  );

  if (!shortRef) {
    return null;
  }

  return (
    <Grid>
      <Cell small={10}>
        <Field name="message" type="text" onChange={onChange} value={message} />
      </Cell>
      <Cell small={2}>
        <ButtonGroup isExpanded={true} style={{ margin: 0 }}>
          <Link onClick={handlePublishEvent}>PublishEvent</Link>
        </ButtonGroup>
      </Cell>
    </Grid>
  );
};

const sm1 = 3;
const sm2 = sm1 - 2;
const sm3 = sm1 - 1;
const sm4 = sm1 + 3;

export const EventRow = ({ event }) => {
  const { purpose } = event;

  switch (purpose) {
    case EVENTS:
      return (
        <Grid>
          <Cell small={sm1}>{event.value.payload.createdAt}</Cell>
          <Cell small={sm2}>{event.purpose}</Cell>
          <Cell small={sm3}>{event.ref}</Cell>
          <Cell small={sm4}>{event.value.payload.requestId}</Cell>
        </Grid>
      );
    case ERRORS:
      return (
        <Grid>
          <Cell small={sm1}>-</Cell>
          <Cell small={sm2}>{event.purpose}</Cell>
          <Cell small={sm3}>{event.ref}</Cell>
          <Cell small={sm4}>
            <pre>{JSON.stringify(event.value)}</pre>
          </Cell>
        </Grid>
      );
    case INGEST:
    default:
      return (
        <Grid>
          <Cell small={sm1}>-</Cell>
          <Cell small={sm2}>{event.purpose}</Cell>
          <Cell small={sm3}>{event.ref}</Cell>
          <Cell small={sm4}>{event.value.message}</Cell>
        </Grid>
      );
  }
};

export const EventsContainer = (props) => {
  const { shortRef, allowPublish } = props;
  const messages = useSelector((state) => getMessages(state, shortRef));

  return (
    <div>
      {allowPublish ? <PublishEvent shortRef={shortRef} /> : null}
      <Grid>
        <Cell small={sm1}>
          <h4>Time</h4>
        </Cell>
        <Cell small={sm2}>
          <h4>Type</h4>
        </Cell>
        <Cell small={sm3}>
          <h4>Binding</h4>
        </Cell>
        <Cell small={sm4}>
          <h4>Event</h4>
        </Cell>
      </Grid>
      {messages.length ? (
        messages.map((ev, i) => <EventRow event={ev} key={i} />)
      ) : (
        <Grid>
          <Cell>
            <center>No Events Received...</center>
          </Cell>
        </Grid>
      )}
    </div>
  );
};

export default EventsContainer;
