import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import $ from 'jquery';

import {
  inProgress,
  getSuggestions,
  suggestDestinations,
  suggestSources,
  clearSuggestions,
} from '../../redux/modules/typeahead';

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => {
  if (typeof suggestion === 'string') {
    return <div>{suggestion}</div>;
  }
  return (
    <div>
      {suggestion.name} ({suggestion.title})
    </div>
  );
};

export class FindEntityContainer extends React.Component {
  constructor(props) {
    super(props);
    const { purpose } = this.props;
    this.state = {
      value: '',
      suggestionsId: `suggestions-${purpose}-pane`,
      foundSuggestions: [],
    };
  }

  handleChange = (event, { newValue }) => {
    this.setState({
      ...this.state,
      value: newValue,
    });
  };

  fetchSuggestions = (inputValue) => {
    if (inputValue.length > 0) {
      const { purpose, entityType, channelRef } = this.props;

      switch (purpose) {
        case 'source':
          this.props.suggestSources(entityType, inputValue, channelRef);
          break;
        case 'destination':
          this.props.suggestDestinations(entityType, inputValue, channelRef);
          break;
        default:
          throw new Error(`Unrecognised Purpose ${purpose}`);
      }
    }
  };

  findSuggestions = (value, suggestions, doFetch = true) => {
    const {
      entityType,
      maxSuggestions = 20,
      updatingMessage = 'updating...',
    } = this.props;
    suggestions = suggestions || this.props.suggestions;

    const fetch = () => {
      if (doFetch) {
        this.fetchSuggestions(value);
      }
    };

    if (value.length === 0) {
      return [];
    }

    if (suggestions[entityType]) {
      const sortedSuggestions = suggestions[entityType].sort((a, b) =>
        a[0].length === b[0].length ? 0 : a[0].length > b[0].length ? -1 : 1
      );

      for (const e of sortedSuggestions) {
        if (e[0] === value) {
          return e[1];
        }
        if (e[0].length > value.length) {
          continue;
        }
        if (value.slice(0, e[0].length) === e[0]) {
          const rval = e[1].filter(
            (m) => getSuggestionValue(m).slice(0, value.length) === value
          );
          if (
            e[1].length === maxSuggestions &&
            rval.length !== maxSuggestions
          ) {
            fetch();
            rval.push(updatingMessage);
          }
          return rval;
        }
      }
    }

    fetch();
    return [updatingMessage];
  };

  onSuggestionsFetchRequested = ({ value: inputValue }) => {
    this.setState({
      foundSuggestions: this.findSuggestions(inputValue),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ foundSuggestions: [] });
  };

  onSuggestionSelected = (cls, { suggestion }) => {
    $(`#${this.state.suggestionsId}`).foundation('close');
    this.props.handleSelection(suggestion);
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const settings = {
      'data-close-on-click': true,
      className: 'dropdown-pane',
      id: this.state.suggestionsId,
      style: { width: 'unset' },
    };

    return (
      <div {...containerProps} {...settings}>
        {children}
      </div>
    );
  };

  componentDidMount() {
    new window.Foundation.Dropdown($(`#${this.state.suggestionsId}`));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entityType } = this.props;
    const { value } = this.state;

    if (nextProps.suggestions[entityType]) {
      this.setState({
        foundSuggestions: this.findSuggestions(
          value,
          nextProps.suggestions,
          false
        ),
      });
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (
  //     props.channelRef !== state.prevPropsChannelRef
  //   ) {
  //     return {
  //       prevPropsChannelRef: props.channelRef,
  //       channelRef: props.channelRef,
  //     };
  //   }
  //   return null;
  // }

  render() {
    const { entityType, disabled } = this.props;
    const { value, foundSuggestions } = this.state;

    const inputProps = {
      placeholder: `Type an ${entityType} name or referenece here`,
      value,
      onChange: this.handleChange,
      className: 'input-group-field',
      type: 'text',
      'data-toggle': this.state.suggestionsId,
      disabled,
    };

    return (
      <Autosuggest
        suggestions={foundSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
      />
    );
  }
}

FindEntityContainer.propTypes = {
  suggestions: PropTypes.object.isRequired,
  inProgress: PropTypes.object,
  getSuggestions: PropTypes.func,
  suggestSources: PropTypes.func.isRequired,
  suggestDestinations: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  handleSelection: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    inProgress: inProgress(state),
    suggestions: getSuggestions(state),
  }),
  (dispatch) => ({
    suggestSources: (entityType, prefix, channelRef) =>
      dispatch(suggestSources(entityType, prefix, channelRef)),
    suggestDestinations: (entityType, prefix, channelRef) =>
      dispatch(suggestDestinations(entityType, prefix, channelRef)),
    clearSuggestions: (entityType) => dispatch(clearSuggestions(entityType)),
  })
)(FindEntityContainer);
