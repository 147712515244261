import { API } from 'aws-amplify';

import { amplifyErrorMessage } from '../redux/helpers/amplify';

export const fetchStatistics = async (bindingId, rollupType) => {
  try {
    const { payload } = await API.get(
      'admin',
      `/bindings/${bindingId}/statistics/${rollupType}`
    );
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export default {
  fetchStatistics,
};
