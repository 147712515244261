/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fuzzyCountryNames = /* GraphQL */ `
  query FuzzyCountryNames(
    $filter: FuzzyCountryNamesFilterInput
    $limit: Int
    $nextToken: Int
  ) {
    fuzzyCountryNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      suggestions {
        text
        score
        freq
      }
      nextToken
    }
  }
`;
export const fuzzyRegionNames = /* GraphQL */ `
  query FuzzyRegionNames(
    $filter: FuzzyRegionNamesFilterInput
    $limit: Int
    $nextToken: Int
  ) {
    fuzzyRegionNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        countryCode
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      suggestions {
        text
        score
        freq
      }
      nextToken
    }
  }
`;
export const searchCountries = /* GraphQL */ `
  query SearchCountries(
    $filter: SearchableCountryFilterInput
    $sort: [SearchableCountrySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCountryAggregationInput]
  ) {
    searchCountries(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchCountryNames = /* GraphQL */ `
  query SearchCountryNames(
    $filter: SearchableCountryNameFilterInput
    $sort: [SearchableCountryNameSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCountryNameAggregationInput]
  ) {
    searchCountryNames(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchOfficialCountryNames = /* GraphQL */ `
  query SearchOfficialCountryNames(
    $filter: SearchableOfficialCountryNameFilterInput
    $sort: [SearchableOfficialCountryNameSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOfficialCountryNameAggregationInput]
  ) {
    searchOfficialCountryNames(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchRegions = /* GraphQL */ `
  query SearchRegions(
    $filter: SearchableRegionFilterInput
    $sort: [SearchableRegionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRegionAggregationInput]
  ) {
    searchRegions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchRegionNames = /* GraphQL */ `
  query SearchRegionNames(
    $filter: SearchableRegionNameFilterInput
    $sort: [SearchableRegionNameSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRegionNameAggregationInput]
  ) {
    searchRegionNames(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        countryCode
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      languageCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        languageCode
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      name {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      official {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      regions {
        items {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        nextToken
      }
      regionTerm
      planet
      region
      subregion
      integrationKey {
        id
        ActionKit
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      languages
      createdAt
      updatedAt
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountryIntgKey = /* GraphQL */ `
  query GetCountryIntgKey($id: ID!) {
    getCountryIntgKey(id: $id) {
      id
      ActionKit
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCountryIntgKeys = /* GraphQL */ `
  query ListCountryIntgKeys(
    $filter: ModelCountryIntgKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountryIntgKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ActionKit
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountryName = /* GraphQL */ `
  query GetCountryName($id: ID!) {
    getCountryName(id: $id) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCountryNames = /* GraphQL */ `
  query ListCountryNames(
    $filter: ModelCountryNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountryNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOfficialCountryName = /* GraphQL */ `
  query GetOfficialCountryName($id: ID!) {
    getOfficialCountryName(id: $id) {
      id
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const listOfficialCountryNames = /* GraphQL */ `
  query ListOfficialCountryNames(
    $filter: ModelOfficialCountryNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfficialCountryNames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      regionCode
      countryCode
      name {
        id
        countryCode
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      country {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      integrationKey {
        id
        ActionKit
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegionIntgKey = /* GraphQL */ `
  query GetRegionIntgKey($id: ID!) {
    getRegionIntgKey(id: $id) {
      id
      ActionKit
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRegionIntgKeys = /* GraphQL */ `
  query ListRegionIntgKeys(
    $filter: ModelRegionIntgKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegionIntgKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ActionKit
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegionName = /* GraphQL */ `
  query GetRegionName($id: ID!) {
    getRegionName(id: $id) {
      id
      countryCode
      region {
        id
        regionCode
        countryCode
        name {
          id
          countryCode
          name
          createdAt
          updatedAt
        }
        country {
          id
          regionTerm
          planet
          region
          subregion
          languages
          createdAt
          updatedAt
        }
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const listRegionNames = /* GraphQL */ `
  query ListRegionNames(
    $filter: ModelRegionNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegionNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        countryCode
        region {
          id
          regionCode
          countryCode
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const countriesByPlanet = /* GraphQL */ `
  query CountriesByPlanet(
    $planet: Planet!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    countriesByPlanet(
      planet: $planet
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name {
          id
          name
          createdAt
          updatedAt
        }
        official {
          id
          name
          createdAt
          updatedAt
        }
        regions {
          nextToken
        }
        regionTerm
        planet
        region
        subregion
        integrationKey {
          id
          ActionKit
          createdAt
          updatedAt
        }
        languages
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
