import React, { useCallback, useState, useEffect } from 'react';
import { Grid, Cell } from 'react-foundation';

export const KeyValueContainer = ({ value, onChange, name }) => {
  const [entries, setEntries] = useState([['', '']]);

  useEffect(() => {
    if (value && Object.keys(value)) {
      setEntries([...Object.entries(value), ['', '']]);
    }
  }, [value]);

  const onLocalChange = useCallback(
    (entries, event) => {
      const { name: tName, value: tValue } = event.target;
      const [i, t] = tName.split('.');
      const newEntry = (([k, v]) => {
        switch (t) {
          case 'key':
            k = tValue;
            break;
          case 'value':
            v = tValue;
            break;
          default:
            break;
        }
        return [k, v];
      })(entries[i]);

      const newEntries = [
        ...entries.slice(0, i),
        newEntry,
        ...entries.slice(i + 1),
      ];

      const [[lk, lv]] = newEntries.slice(-1);
      if (lk !== '' || lv !== '') {
        newEntries.push(['', '']);
      }
      setEntries(newEntries);

      const result = newEntries.reduce((a, [k, v]) => {
        if (k !== '' || v !== '') {
          a[k] = v;
        }
        return a;
      }, {});
      onChange({ target: { name, value: result } });
    },
    [onChange, name]
  );

  return (
    <Grid className="input-group">
      <Cell small={6}>
        <span className="input-group-label">NAME</span>
      </Cell>
      <Cell small={6}>
        <span className="input-group-label">VALUE</span>
      </Cell>
      <Cell small={12}>
        {entries.map(([k, v], i) => (
          <Grid key={i}>
            <Cell small={6}>
              <input
                className="input-group-field"
                type="text"
                onChange={(e) => onLocalChange(entries, e)}
                name={`${i}.key`}
                value={k}
              />
            </Cell>
            <Cell small={6}>
              <input
                className="input-group-field"
                type="text"
                onChange={(e) => onLocalChange(entries, e)}
                name={`${i}.value`}
                value={v}
              />
            </Cell>
          </Grid>
        ))}
      </Cell>
    </Grid>
  );
};

export default KeyValueContainer;
