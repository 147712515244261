import { useMemo } from 'react';
import { push, getLocation, getHash } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

export const pathUp = (dispatch, getState) => {
  const location = getLocation(getState());
  _pathUp(dispatch, location);
};

const _pathUp = (dispatch, location) => {
  if (location) {
    const newPath = location.pathname.split('/').slice(0, -1).join('/');
    dispatch(push(newPath));
  }
};

export const useNavigation = () => {
  const dispatch = useDispatch();
  const location = useSelector(getLocation);
  const hash = useSelector(getHash);

  const api = useMemo(
    () => ({
      pathUp: () => _pathUp(dispatch, location),
      section: location + hash,
      location,
      hash,
    }),
    [dispatch, location, hash]
  );

  return api;
};

export default useNavigation;
