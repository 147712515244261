import React, { useMemo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Callout, Grid, Cell, Menu, MenuItem } from 'react-foundation';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  DiscreteColorLegend,
} from 'react-vis';

import { getBindings } from '../../redux/modules/bindings';
import { fetchStatistics } from '../../rest/statistics';

const ALL_ROLLUPS = ['Hourly', 'Daily', 'Monthly'];
const ROLLUPS = ['Daily'];
const [ROLLUP_HOURLY, ROLLUP_DAILY, ROLLUP_MONTHLY] = ALL_ROLLUPS;

export const GraphContainer = () => {
  const bindings = useSelector(getBindings);
  const [rollupType, setRollupType] = useState(ROLLUP_DAILY);
  const [statistics, setStatistics] = useState([]);
  const [graphArgs, setGraphArgs] = useState([]);
  const [wH, setWH] = useState({
    gWidth: 300,
    gHeight: 300,
    lWidth: 200,
    lHeight: 300,
  });

  useEffect(() => {
    switch (rollupType) {
      case ROLLUP_HOURLY:
      case ROLLUP_MONTHLY:
      case ROLLUP_DAILY:
        Promise.all(
          bindings.map((b) => fetchStatistics(b.bindingId, rollupType))
        ).then(setStatistics);
        break;
      default:
        setStatistics(bindings.map((b) => b.statistics));
    }
  }, [bindings, rollupType, setStatistics]);

  useEffect(() => {
    setGraphArgs(
      statistics.map((s) => {
        return {
          data: s.map((s, i) => ({ x: i, y: s.total })),
        };
      })
    );
  }, [statistics, setGraphArgs]);

  const xTickFormat = useMemo(() => {
    if (statistics[0]) {
      const dates = statistics[0].map(({ date }) => date);
      return (i) => dates[i];
    }
  }, [statistics]);

  const legendItems = useMemo(() => {
    return bindings.map((b) => b.name);
  }, [bindings]);

  const graphRef = useRef(null);
  const legendRef = useRef(null);

  useEffect(() => {
    if (graphRef.current && graphRef.current.getBoundingClientRect().width) {
      const gRect = graphRef.current.getBoundingClientRect();
      const lRect = legendRef.current.getBoundingClientRect();
      setWH({
        gWidth: gRect.width,
        gHeight: gRect.height,
        lWidth: lRect.width,
        lHeight: lRect.height,
      });
    }
  }, [graphRef, legendRef]);

  const { gWidth, lWidth, lHeight } = wH;

  return (
    <Grid>
      <Cell small={10}>
        <Menu>
          {ROLLUPS.map((r, i) => (
            <MenuItem key={i} isActive={r === rollupType}>
              <a href={`#${r}`} onClick={() => setRollupType(r)}>
                {r}
              </a>
            </MenuItem>
          ))}
        </Menu>
        <div ref={graphRef}>
          <XYPlot width={gWidth} height={lHeight} margin={75}>
            <HorizontalGridLines />
            {graphArgs.map((g, i) => (
              <LineSeries key={i} data={g.data} />
            ))}
            <XAxis tickFormat={xTickFormat} tickLabelAngle={300} />
            <YAxis />
          </XYPlot>
        </div>
      </Cell>
      <Cell small={2}>
        <div ref={legendRef}>
          <Callout>
            <DiscreteColorLegend
              height={lHeight}
              width={lWidth}
              items={legendItems}
            />
          </Callout>
        </div>
      </Cell>
    </Grid>
  );
};

export default GraphContainer;
