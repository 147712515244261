import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import typeahead from './modules/typeahead';
import bindings, { listeners as bindingsListeners } from './modules/bindings';
import auth from './modules/auth';
import pubsub, { listeners as pubsubListeners } from './modules/pubsub';
import events, { listeners as eventsListeners } from './modules/events';
import { makeReducer } from './modules/listeners';

export default (history) =>
  combineReducers({
    bindings,
    auth,
    typeahead,
    events,
    pubsub,
    listeners: makeReducer(pubsubListeners, bindingsListeners, eventsListeners),
    router: connectRouter(history),
  });
