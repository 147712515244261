import React, { useState } from 'react';
import { Button, Cell, Grid } from 'react-foundation';

function getStateFromTranslations(translations) {
  if (translations && translations.name) {
    return JSON.parse(translations.name);
  } else {
    return {};
  }
}

const AddLanguage = (props) => {
  const { addLanguage } = props;
  const [language, setLanguage] = useState('');

  const onChangeLanguage = (event) => {
    const { value } = event.target;
    setLanguage(value);
  };

  const onClick = (event) => {
    event.preventDefault();
    if (addLanguage) {
      addLanguage(language);
    } else {
      console.log('no addLanguage handler');
    }
    setLanguage('');
  };

  return (
    <Grid className="input-group">
      <Cell small={2}>
        <input
          className="input-group-field"
          type="text"
          placeholder="Name"
          name="language"
          value={language}
          onChange={onChangeLanguage}
        />
      </Cell>
      <Cell small={10}>
        <Button onClick={onClick} isDisabled={!language}>
          Add Language
        </Button>
      </Cell>
    </Grid>
  );
};

const Fields = ({ values, onChange, id }) =>
  Object.keys(values)
    .sort()
    .map((language) => {
      const key = `${id}-${language}`;
      return (
        <Grid className="input-group" key={key}>
          <Cell className="input-group-label" small={2}>
            {language}
          </Cell>
          <Cell small={10}>
            <input
              className="input-group-field"
              type="text"
              placeholder="Name"
              name={language}
              value={values[language]}
              onChange={onChange}
            />
          </Cell>
        </Grid>
      );
    });

export const Translations = (props) => {
  const { translations, update } = props;
  const initialState = getStateFromTranslations(translations);
  const id = translations ? translations.id : null;
  const [values, setValues] = useState(initialState);
  const [changed, setChanged] = useState(false);

  if (!values && translations) {
    setValues(getStateFromTranslations(translations));
  }

  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setChanged(true);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setChanged(false);
    if (update) {
      const result = await update({ id, name: JSON.stringify(values) });
      setValues(JSON.parse(result.name));
    }
  };

  const addLanguage = (language) => {
    setValues({ ...values, [language]: '' });
    setChanged(true);
  };

  return (
    <form onSubmit={onSubmit}>
      <Fields values={values} onChange={onChange} id={id} />
      <AddLanguage addLanguage={addLanguage} />
      <Button type={'submit'} isDisabled={!changed}>
        Save Translations
      </Button>
    </form>
  );
};

export default Translations;
