import React, {
  useContext,
  useMemo,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';

import { isAuthenticated } from '../redux/modules/auth';

export const ConfigContext = createContext();

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigContextProvider = ({ children }) => {
  const [config, setConfig] = useState(false);
  const hasAuth = useSelector(isAuthenticated);

  const fetchConfig = useCallback(async () => {
    const { payload } = await API.get('admin', '/config');
    setConfig(payload);
  }, [setConfig]);

  useEffect(() => {
    if (hasAuth && !config) {
      fetchConfig();
    }
    if (!hasAuth && config) {
      setConfig(false);
    }
  }, [hasAuth, config, fetchConfig, setConfig]);

  const value = useMemo(() => ({ brokerConfig: config }), [config]);

  return (
    <ConfigContext.Provider value={value}>
      {hasAuth && <div>{children}</div>}
    </ConfigContext.Provider>
  );
};

export default useConfigContext;
