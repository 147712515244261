import { createAction, createReducer } from '../helpers/redux';

const LISTENER_ADD = 'messaging/listeners/LISTENER_ADD';

// State
const initialState = {};

// Actions
export const addListener = createAction(LISTENER_ADD, 'type', 'func');

// Reducers
const _makeReducer = (_initialState) =>
  createReducer(_initialState, {
    [LISTENER_ADD]: _addListenerReducer,
  });

const _addListenerReducer = (state, { type, func }) => ({
  ...state,
  [type]: [...(state[type] || []), func],
});

export default _makeReducer(initialState);

export const makeReducer = (...listeners) => {
  let _initialState = { ...initialState };
  for (const listener of listeners) {
    for (const type of Object.keys(listener)) {
      _initialState = _addListenerReducer(_initialState, {
        type,
        func: listener[type],
      });
    }
  }
  return _makeReducer(_initialState);
};

// Selectors
export const getListeners = (state, type) => state.listeners[type];
