const INGEST = 'ingest';
const ERRORS = 'errors';
const EVENTS = 'events';
const ACTIONS = 'actions';

export const MESSAGE_PURPOSES = {
  INGEST,
  ERRORS,
  EVENTS,
  ACTIONS,
};
