import React, { useCallback, useMemo, useEffect } from 'react';

import useBindingContext from './useBindingContext';
import { FieldGrid } from '../Fields';
import Description from '../Entity/Description';
import Fields from '../Entity/Fields';

import { ENTITY_CONFIG } from '../Entity/constants';

export const ActionFields = (props) => {
  const [binding, api] = useBindingContext();

  const { actionType, action = {} } = binding;
  const { setFields } = api.action;

  return (
    <Fields entity={action} entityType={actionType} setFields={setFields} />
  );
};

export const ActionContainer = (props) => {
  const [state] = useBindingContext();
  const { actionType } = state;

  return (
    <div>
      <Description entityType={actionType} />
      <ActionFields />
    </div>
  );
};

export const ActionType = (props) => {
  const [state, api] = useBindingContext();

  const { entityType } = state.destination;

  const actionTypeNames = useMemo(
    () => ENTITY_CONFIG[entityType].actionTypes || [],
    [entityType]
  );

  useEffect(() => {
    if (!state.actionType.startsWith(entityType)) {
      const newActionType = actionTypeNames[0];
      if (newActionType && newActionType !== state.actionType) {
        api.action.setEntityType(newActionType);
      }
    }
  }, [api.action, state.actionType, entityType, actionTypeNames]);

  const onChange = useCallback(
    (event) => {
      api.action.setEntityType(event.target.value);
    },
    [api.action]
  );

  return (
    <FieldGrid name="Action Type">
      <select
        className="input-group-field"
        onChange={onChange}
        name="actionType"
        value={state.actionType}
      >
        {actionTypeNames.map((s, i) => (
          <option key={i} value={s}>
            {s}
          </option>
        ))}
      </select>
    </FieldGrid>
  );
};

export default ActionContainer;
