import React, {
  useCallback,
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getHash, push, getLocation } from 'connected-react-router';

import {
  Cell,
  Grid,
  Icon,
  Badge,
  ButtonGroup,
  Colors,
  Button,
  Callout,
} from 'react-foundation';

import Action, { ActionType } from '../Action';
import Trigger, { TriggerType } from '../Trigger';
import Source, { SourceType } from '../Source';
import Destination, { DestinationType } from '../Destination';
import General from '../General';
import useBindingContext from '../useBindingContext';
import { ButtonsSection } from './Buttons';

const steps = ['general', 'source', 'trigger', 'destination', 'action'];

const WizardContext = createContext();

const useWizardContext = () => useContext(WizardContext);

const Buttons = () => {
  const [step, setStep] = useWizardContext();
  const [state, { crud }] = useBindingContext();
  const activeIdx = steps.indexOf(step);
  const dispatch = useDispatch();
  const location = useSelector(getLocation);

  const handleCancel = useCallback(
    (event) => {
      event.preventDefault();
      const newPath = location.pathname.split('/').slice(0, -1).join('/');
      dispatch(push(newPath));
    },
    [dispatch, location]
  );

  const handleCreate = useCallback(
    (event) => {
      event.preventDefault();
      crud.create(state);
    },
    [state, crud]
  );

  const handleNext = useCallback(
    (event) => {
      event.preventDefault();
      if (activeIdx < steps.length - 1) {
        setStep(steps[activeIdx + 1]);
      }
    },
    [activeIdx, setStep]
  );

  const handlePrevious = useCallback(
    (event) => {
      event.preventDefault();
      if (activeIdx) {
        setStep(steps[activeIdx - 1]);
      }
    },
    [activeIdx, setStep]
  );

  return (
    <ButtonsSection>
      <ButtonGroup isExpanded>
        <Button onClick={handleCancel}>Cancel</Button>
        {activeIdx ? <Button onClick={handlePrevious}>Previous</Button> : ''}
        {activeIdx === steps.length - 1 ? (
          <Button onClick={handleCreate}>Create</Button>
        ) : (
          <Button onClick={handleNext}>Next</Button>
        )}
      </ButtonGroup>
    </ButtonsSection>
  );
};

const Navigation = () => {
  const [step] = useWizardContext();
  const activeIdx = steps.indexOf(step);
  return (
    <Grid className="text-center">
      {steps.map((s, i) => (
        <Cell key={i} small={2} offsetOnSmall={i ? 0 : 1}>
          {i > activeIdx ? (
            <Badge color={Colors.SECONDARY}>&bull;</Badge>
          ) : i === activeIdx ? (
            <Badge>
              <Icon name="fi-record" />
            </Badge>
          ) : (
            <Badge>
              <Icon name="fi-check" />
            </Badge>
          )}
          <br />
          <Link to={`#${s}`}>
            {i + 1}. {s.replace(/^./, s[0].toUpperCase())}
          </Link>
        </Cell>
      ))}
    </Grid>
  );
};

const Step = ({ children, name }) => {
  const [step] = useWizardContext();
  return <div className={step === name ? 'show' : 'hide'}>{children}</div>;
};

export const FormWizard = () => {
  const [step, setStep] = useState('general');
  const hash = useSelector(getHash);

  useEffect(() => {
    if (hash) {
      setStep(hash.substring(1));
    }
  }, [setStep, hash]);

  return (
    <WizardContext.Provider value={[step, setStep]}>
      <Grid>
        <Cell>
          <Navigation />
        </Cell>
        <Cell>
          <br />
          <Step name="general">
            <Callout color={Colors.PRIMARY}>
              <p>
                A <i>Binding</i> translates <i>Events</i> ingested via a{' '}
                <i>Source</i> which are then manipulated and delivered to a{' '}
                <i>Destination</i>.
              </p>

              <p>
                A descriptive name for a <i>Binding</i> is helpful to avoid
                confusion.
              </p>
            </Callout>
            <General />
          </Step>
          <Step name="source">
            <Callout color={Colors.PRIMARY}>
              <p>
                A <i>Source</i> defines the incoming pathway that <i>Events</i>{' '}
                for this <i>Binding</i> will arrive.
              </p>

              <p>
                Some <i>Sources</i> can be shared accross <i>Bindings</i>, with
                specific details of when a <i>Binding</i> should be executed
                stored as a <i>Trigger</i>.
              </p>
            </Callout>
            <SourceType />
            <Source />
          </Step>
          <Step name="trigger">
            <Callout color={Colors.PRIMARY}>
              <p>
                A <i>Trigger</i> defines parameters for inspecting an{' '}
                <i>Event</i> to decide if in fact the <i>Binding</i> should be
                executed.
              </p>

              <p>
                <i>Triggers</i> are not necassary for simple <i>Events</i>.
              </p>
            </Callout>
            <TriggerType />
            <Trigger />
          </Step>
          <Step name="destination">
            <Callout color={Colors.PRIMARY}>
              <p>
                A <i>Destination</i> defines where messages will be sent when
                the <i>Binding</i> is executed.
              </p>

              <p>
                <i>Destinations</i> may be shared by <i>Bindings</i>, with{' '}
                specific details of the communication stored as an <i>Action</i>
                .
              </p>
            </Callout>
            <DestinationType />
            <Destination />
          </Step>
          <Step name="action">
            <Callout color={Colors.PRIMARY}>
              <p>
                An <i>Action</i> defines parameters for specifying how a{' '}
                <i>Destination</i> is communicated with.
              </p>
            </Callout>
            <ActionType />
            <Action />
          </Step>
        </Cell>
        <Cell>
          <Buttons />
        </Cell>
      </Grid>
    </WizardContext.Provider>
  );
};

export default FormWizard;
