import React from 'react';

export const Welcome = () => (
  <div>
    <h3>Welcome</h3>
    <hr />
    <p>Welcome to the ONE Campaign Bridge</p>
  </div>
);

export default Welcome;
