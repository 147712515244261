import assert from 'assert';
import { API } from 'aws-amplify';
import { scrubEntity } from '@one/entity-helper';

import { amplifyErrorMessage } from '../redux/helpers/amplify';

export const createBinding = async (binding) => {
  try {
    const { payload } = await API.post('admin', '/bindings', {
      body: binding,
    });
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export const updateBinding = async (binding) => {
  try {
    const { payload } = await API.put(
      'admin',
      `/bindings/${binding.bindingId}`,
      {
        body: {
          ...binding,
          source: scrubEntity(binding.source),
          destination: scrubEntity(binding.destination),
        },
      }
    );
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export const fetchBindings = async () => {
  try {
    const { payload } = await API.get('admin', '/bindings');
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export const deleteBinding = async (bindingId) => {
  try {
    assert(bindingId, 'missing bindingId');
    const { payload } = await API.del('admin', `/bindings/${bindingId}`);
    return payload;
  } catch (error) {
    throw new Error(amplifyErrorMessage(error));
  }
};

export default {
  createBinding,
  updateBinding,
  fetchBindings,
  deleteBinding,
};
