import React from 'react';
// import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { Sparklines, SparklinesBars } from 'react-sparklines';
import EntityListDesc from '../Entity/ListDesc';
import { Icon } from 'react-foundation';

import {
  getBindings,
  fetchBindings,
  inProgress,
} from '../../redux/modules/bindings';

const ListRow = ({ binding }) => {
  const { bindingId, name, source, destination, counts, broadcastEventsLive } =
    binding;

  // const data = useMemo(() => {
  //   return binding.statistics.map((v) => v.total);
  // }, [binding]);

  return (
    <tr>
      <td>
        <Link to={`bindings/${bindingId}`}>{name}</Link>
      </td>
      <td>
        <EntityListDesc entity={source} />
        <EntityListDesc entity={destination} />
      </td>
      {/* <td>
        <Sparklines data={data}>
          <SparklinesBars />
        </Sparklines>
      </td> */}
      <td>{counts.total}</td>
      <td>{broadcastEventsLive ? <Icon name="fi-rss" /> : null}</td>
    </tr>
  );
};

export class ListContainer extends React.Component {
  render() {
    const { bindings } = this.props;

    return (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>
              Source <Icon name="fi-arrow-right" /> Destination
            </th>
            {/* <th>Daily</th> */}
            <th>Count</th>
            <th>Live Feedback</th>
          </tr>
        </thead>
        <tbody>
          {bindings.map((binding) => (
            <ListRow key={binding.bindingId} binding={binding} />
          ))}
        </tbody>
      </table>
    );
  }

  static propTypes = {
    bindings: PropTypes.array.isRequired,
    fetchBindings: PropTypes.func,
    inProgress: PropTypes.bool,
  };
}

export default connect(
  (state) => ({
    bindings: getBindings(state),
    inProgress: inProgress(state),
  }),
  (dispatch) => ({
    fetchBindings: () => dispatch(fetchBindings()),
  })
)(ListContainer);
