import React, { useMemo } from 'react';

import Fields from '../Fields';

import { ENTITY_CONFIG } from './constants';

export const EntityFieldsContainer = (props) => {
  let { entityType, setFields, ...args } = props;
  const { entity, api = {}, ...rest } = args;

  setFields = setFields || api.setFields;

  entityType = entityType || entity.entityType;
  const isEdit = entity.entityId;

  const fields = useMemo(() => {
    const entityConfig = ENTITY_CONFIG[entityType];

    if (entityConfig && entityConfig.fields) {
      return entityConfig.fields.map((f) => {
        if (f.requiresAuth) {
          return { ...f, suppressed: true };
        }

        if (isEdit) {
          const output = { ...f };

          if (f.isServerSide) {
            if (isEdit) {
              if (f.disabled) {
                output.type = 'alert';
              } else {
                output.revealSet = true;
              }
            }
            output.coearceValue = true;
          }

          if (isEdit) {
            if (!f.canUpdate) {
              output.disabled = true;
            }
          }
          return output;
        } else {
          return f;
        }
      });
    }
    return [];
  }, [entityType, isEdit]);

  return (
    <Fields
      {...{
        ...rest,
        setFields,
        fields,
        values: entity.body || entity,
      }}
    />
  );
};

export default EntityFieldsContainer;
