import awsmobile from './graphql/aws-exports';

const apiDomain = process.env.REACT_APP_API_DOMAIN;

const prod = {
  publicName: process.env.REACT_APP_PUBLIC_NAME,
  adminEndpoint: `https://${apiDomain}/admin`,
  brokerEndpoint: `https://${apiDomain}/broker`,
  publicEndpoint: `https://${apiDomain}/public`,
};

const dev = {
  publicName: `${process.env.REACT_APP_PUBLIC_NAME}Dev`,
  adminEndpoint: `https://${apiDomain}/admin`,
  brokerEndpoint: `https://${apiDomain}/broker`,
  publicEndpoint: `https://${apiDomain}/public`,
};

const local = {
  publicName: `${process.env.REACT_APP_PUBLIC_NAME}Local`,
  adminEndpoint: `http://localhost:9001/admin`,
  brokerEndpoint: `http://localhost:9001/broker`,
  publicEndpoint: `http://localhost:9001/public`,
};

export const stage = process.env.REACT_APP_STAGE || 'dev';
export const debug = process.env.REACT_APP_DEBUG;
export const region = 'eu-west-1';
export const identityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const awsIotEnpoint = process.env.REACT_APP_AWS_IOT_ENDPOINT;

export const config = { debug, region, identityPoolId, awsIotEnpoint, stage };

switch (stage) {
  case 'prod':
    Object.assign(config, prod);
    break;
  case 'local':
    Object.assign(config, local);
    break;
  default:
    Object.assign(config, dev);
}

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const pickUri = (uris) => {
  uris = uris.split(',');
  if (uris.length === 1) return uris[0];
  return uris.find((uri) => isLocalhost === Boolean(uri.match('localhost')));
};

export const publicName = config.publicName;

export const cookieStorage = {
  domain: window.location.hostname,
  secure: !isLocalhost,
};

export const aws_exports = {
  Auth: {
    cookieStorage,
  },
  API: {
    endpoints: [
      {
        name: 'admin',
        endpoint: config.adminEndpoint,
        region,
      },
      {
        name: 'broker',
        endpoint: config.brokerEndpoint,
        region,
      },
      {
        name: 'public',
        endpoint: config.publicEndpoint,
        region,
      },
    ],
  },
  Analytics: {
    disabled: true,
  },
  ...awsmobile,
  oauth: {
    ...awsmobile.oauth,
    redirectSignIn: pickUri(awsmobile.oauth.redirectSignIn),
    redirectSignOut: pickUri(awsmobile.oauth.redirectSignOut),
  },
};

Object.assign(config, { aws_exports });

export default config;
