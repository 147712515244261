import React from 'react';
import { Cell } from 'react-foundation';
import 'flag-icon-css/css/flag-icon.min.css';

export const Flag = ({ countryCode }) => {
  const iconClass = 'flag-icon flag-icon-' + countryCode.toLowerCase();
  return <span className={iconClass} />;
};

export const FlagCell = ({ countryCode }) => {
  const iconClass =
    'flag-icon-backgroud flag-icon-' + countryCode.toLowerCase();
  return (
    <Cell className={iconClass} auto="all">
      <div
        style={{ width: '100%', paddingBottom: '75%' }}
        className={iconClass}
      />
    </Cell>
  );
};

export default Flag;
