import React, { useMemo } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'react-foundation';

import Binding, { BindingList } from '../../components/Binding';
import { ChannelContextProvider } from '../../components/Entity/useChannelContext';
import { ErrorContextProvider } from '../../hooks/useErrorContext';

import { getBindings } from '../../redux/modules/bindings';

const BindingPage = ({ name, children }) => (
  <div>
    {name ? <h3>Binding: {name}</h3> : <h3>Bindings</h3>}
    <hr />
    {children}
  </div>
);

const List = () => (
  <BindingPage>
    <Link to="/bindings/create">
      <Button>Create Binding</Button>
    </Link>
    <BindingList />
  </BindingPage>
);

const Edit = ({ history }) => {
  const [bindingId] = history.location.pathname.split('/').slice(-1);
  const bindings = useSelector(getBindings);
  const binding = useMemo(
    () => bindings.find((b) => b.bindingId === bindingId),
    [bindings, bindingId]
  );

  if (binding) {
    return (
      <BindingPage name={binding.name}>
        <Binding binding={binding} />
      </BindingPage>
    );
  } else {
    return <BindingPage />;
  }
};

const Create = () => (
  <BindingPage>
    <Binding />
  </BindingPage>
);

export const BindingsContainer = () => (
  <ErrorContextProvider>
    <ChannelContextProvider>
      <Switch>
        <Route exact path="/bindings" component={List} />
        <Route path="/bindings/create" component={Create} />
        <Route path="/bindings" component={Edit} />
      </Switch>
    </ChannelContextProvider>
  </ErrorContextProvider>
);

export default BindingsContainer;
