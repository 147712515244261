import React, { useCallback } from 'react';

import useBindingContext from './useBindingContext';
import { Field } from '../Fields';

export const General = () => {
  const [state, api] = useBindingContext();

  const onChange = useCallback(
    (event) => {
      api.setField(event.target.name, event.target.value);
    },
    [api]
  );

  return (
    <div>
      <Field
        name="name"
        isRequired={true}
        onChange={onChange}
        value={state.name}
        type="text"
      />
      <Field
        name="broadcastEventsLive"
        onChange={onChange}
        value={state.broadcastEventsLive}
        type="switch"
      />
    </div>
  );
};

export default General;
