import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BreadcrumbItem, Breadcrumbs } from 'react-foundation';
import { Link } from 'react-router-dom';

import { publicName } from '../../config';

export class BreadcrumbsContainer extends React.Component {
  render = () => {
    const { pathname } = this.props;

    const [current] = pathname.split('/').slice(-1);
    let path = '';
    return (
      <Breadcrumbs>
        <BreadcrumbItem>
          <Link to="/">{publicName}</Link>
        </BreadcrumbItem>
        {pathname
          .split('/')
          .slice(1)
          .map((folder) => {
            path = path + '/' + folder;
            if (folder === current) {
              return (
                <BreadcrumbItem isDisabled key={path}>
                  {folder}
                </BreadcrumbItem>
              );
            } else {
              return (
                <BreadcrumbItem key={path}>
                  <Link to={path}>{folder}</Link>
                </BreadcrumbItem>
              );
            }
          })}
      </Breadcrumbs>
    );
  };

  static propTypes = {
    pathname: PropTypes.string.isRequired,
  };
}

export default connect((state) => ({
  pathname: state.router.location.pathname,
}))(BreadcrumbsContainer);
