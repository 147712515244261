import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';

import listenerMiddleware from './redux/middleware/listener';
import createRootReducer from './redux/reducer';

import { REBOOT } from './redux/constants';

const initialState = {};

export const history = createBrowserHistory();

const store = createStore(
  createRootReducer(history),
  initialState,
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware,
      listenerMiddleware
    )
  )
);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./redux/reducer', () => {
    store.replaceReducer(createRootReducer(history));
  });
}

store.dispatch({ type: REBOOT });

export default store;
