import { API } from 'aws-amplify';

import { createReducer, createAction } from '../helpers/redux';
import { createAmplifyErrorAction } from '../helpers/amplify';

const SUGGEST = 'messaging/typeahead/SUGGEST';
const SUGGEST_SUCCESS = 'messaging/typeahead/SUGGEST_SUCCESS';
const SUGGEST_FAILURE = 'messaging/typeahead/SUGGEST_FAILURE';
const SUGGEST_CLEAR = 'messaging/typeahead/SUGGEST_CLEAR';

// State
const initialState = {
  suggestions: {},
  inProgress: {},
};

// Actions
const suggest_begin = createAction(SUGGEST, 'entityType');
const suggest_success = createAction(
  SUGGEST_SUCCESS,
  'entityType',
  'prefix',
  'suggestions'
);
const suggest_failure = createAmplifyErrorAction(SUGGEST_FAILURE);

export const suggest =
  (purpose, entityType, prefix, channelRef) => async (dispatch) => {
    const [destination, type] = entityType.split(':');
    dispatch(suggest_begin(entityType));
    try {
      const { payload } = await API.post(
        'admin',
        `/${purpose}/${destination}/${type}/typeahead`,
        { body: { prefix, channelRef } }
      );
      dispatch(suggest_success(entityType, prefix, payload));
    } catch (error) {
      dispatch(suggest_failure(error));
    }
  };

export const suggestDestinations = (...args) => suggest('destination', ...args);
export const suggestSources = (...args) => suggest('source', ...args);

export const clearSuggestions = createAction(SUGGEST_CLEAR, 'entity');

// Reducer helpers
function decrementProgress(state, entityType) {
  const counter = state.inProgress[entityType] || 1;
  return {
    ...state.inProgress,
    [entityType]: counter - 1,
  };
}

function incrementProgress(state, entityType) {
  const counter = state.inProgress[entityType] || 0;
  return {
    ...state.inProgress,
    [entityType]: counter + 1,
  };
}

// Reducers
export default createReducer(initialState, {
  [SUGGEST_SUCCESS]: (state, { suggestions, entityType, prefix }) => ({
    ...state,
    suggestions: {
      ...state.suggestions,
      [entityType]: [
        [prefix, suggestions],
        ...(state.suggestions[entityType] || []).slice(0, 20),
      ],
    },
    inProgress: decrementProgress(state, entityType),
  }),
  [SUGGEST_FAILURE]: (state, { entityType }) => ({
    ...state,
    inProgress: decrementProgress(state, entityType),
  }),
  [SUGGEST]: (state, { entityType }) => ({
    ...state,
    inProgress: incrementProgress(state, entityType),
  }),
  [SUGGEST_CLEAR]: (state, { entityType }) => ({
    ...state,
    inProgress: { ...state.inProgress, [entityType]: 0 },
    suggestions: { ...state.suggestions, [entityType]: [] },
  }),
});

// Selectors
export const getSuggestions = (state) => state.typeahead.suggestions || {};
export const inProgress = (state) => state.typeahead.inProgress || {};
