import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Country, ListCountries, Region } from '../../components/Countries';

function getRegionPath(countryCode, regionCode) {
  return `/countries/${countryCode}/regions/${regionCode}`;
}

// TODO: useContext for path etc.
export class CountriesContainer extends React.Component {
  render() {
    const { history } = this.props;
    const countryCode = history.location.pathname.split('/')[2];
    const regionCode = history.location.pathname.split('/')[4];
    const regionPath = getRegionPath(countryCode, regionCode);

    return (
      <Switch>
        <Route exact path="/countries" component={ListCountries} />
        <Route
          path={regionPath}
          render={() => (
            <Region {...{ countryCode, regionCode, history, getRegionPath }} />
          )}
        />
        <Route
          path="/countries"
          render={() => (
            <Country {...{ countryCode, history, getRegionPath }} />
          )}
        />
      </Switch>
    );
  }
}

export default CountriesContainer;
