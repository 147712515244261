import React, { useState, useEffect, useRef } from 'react';

import { Tabs, TabsContent, TabItem, TabPanel } from 'react-foundation';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getHash } from 'connected-react-router';
import IframeResizer from 'iframe-resizer-react';

import useConfigContext from '../../hooks/useConfigContext';

export const JSDocContainer = (props) => {
  const [isActive, setIsActive] = useState(props.isActive);
  const iframeRef = useRef();
  const log = true;

  useEffect(() => {
    if (props.isActive !== isActive && iframeRef.current) {
      if (props.isActive) {
        iframeRef.current.resize();
      }
      setIsActive(props.isActive);
    }
  }, [props.isActive, isActive]);

  return (
    <IframeResizer
      forwardRef={iframeRef}
      log={log}
      src="/docs/jsdoc/index.html"
      scrolling="no"
      frameBorder={0}
      style={{ minWidth: '100%' }}
    />
  );
};

export const SpectaqlContainer = (props) => {
  const [isActive, setIsActive] = useState(props.isActive);
  const iframeRef = useRef();
  const log = true;

  useEffect(() => {
    if (props.isActive !== isActive && iframeRef.current) {
      if (props.isActive) {
        iframeRef.current.resize();
      }
      setIsActive(props.isActive);
    }
  }, [props.isActive, isActive]);

  return (
    <IframeResizer
      forwardRef={iframeRef}
      log={log}
      src="/docs/spectaql/index.html"
      scrolling="no"
      frameBorder={0}
      style={{ minWidth: '100%' }}
    />
  );
};

export const RedocContainer = (props) => {
  const [isActive, setIsActive] = useState(props.isActive);
  const iframeRef = useRef();
  const log = true;

  useEffect(() => {
    if (props.isActive !== isActive && iframeRef.current) {
      if (props.isActive) {
        iframeRef.current.resize();
      }
      setIsActive(props.isActive);
    }
  }, [props.isActive, isActive]);

  return (
    <IframeResizer
      forwardRef={iframeRef}
      log={log}
      src="/docs/redoc/index.html"
      scrolling="no"
      frameBorder={0}
      style={{ minWidth: '100%' }}
    />
  );
};

export const DocumentationContainer = () => {
  const [isActive, setIsActive] = useState('jsdoc');
  const hash = useSelector(getHash);

  useEffect(() => {
    if (hash) {
      setIsActive(hash.substring(1));
    }
  }, [hash, setIsActive]);

  return (
    <div>
      <Tabs>
        <TabItem isActive={isActive === 'jsdoc'}>
          <Link to="#jsdoc">Docs Home</Link>
        </TabItem>
        <TabItem isActive={isActive === 'redoc'}>
          <Link to="#redoc">REST API</Link>
        </TabItem>
        <TabItem isActive={isActive === 'spectaql'}>
          <Link to="#spectaql">GraphQL API</Link>
        </TabItem>
      </Tabs>
      <TabsContent>
        <TabPanel id="redoc" isActive={isActive === 'redoc'}>
          <RedocContainer />
        </TabPanel>
        <TabPanel id="jsdoc" isActive={isActive === 'jsdoc'}>
          <JSDocContainer isActive={isActive === 'jsdoc'} />
        </TabPanel>
        <TabPanel id="spectaql" isActive={isActive === 'spectaql'}>
          <SpectaqlContainer isActive={isActive === 'spectaql'} />
        </TabPanel>
      </TabsContent>
    </div>
  );
};

export default DocumentationContainer;
